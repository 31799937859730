import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { IMeetingUser, eMeetingUserState } from "../models/IMeetingUser";
import { OAuthStorageAdapter } from "../OAuthStorageAdapter";
import { Subject } from "rxjs";
import { IMeetingInvitation } from "../models/IMeetingInvitation";
import { AuthenticationService } from "./authentication.service";
import { NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from "rxjs/operators";

enum eSocketEvents {
    reconnect = "reconnect", // on connect
    // Incomming.
    identityAccepted = "identity_accepted",
    connectedUsers = "connected_users",
    updateUserState = "update_user_state",
    meetingNegotiated = "meeting_negotiated",
    forceLogout = "force_logout", // <= server tells client to logout (GMP-24: multiple logins should be forbidden)
    usersAttendedMeeting = "users_attended_meeting",
    userJoinedMeeting = "user_joined_meeting",
    // Outgoing.
    changeState = "change_state",
    listConnectedUsers = "list_connected_users",
    invitationSent = "invitation_sent",
    invitationAccepted = "invitation_accepted",
    invitationRejected = "invitation_rejected",
    invitationCancelled = "invitation_cancelled",
    identify = "identify",
    userLeftMeeting = "user_left_meeting"
}

@Injectable({
    providedIn: "root"
})
export class MeetingsControlService {
    public user?: IMeetingUser;
    public userSubject: Subject<IMeetingUser> = new Subject();
    userSubject$ = this.userSubject.asObservable();

    public onlineUsers: Array<IMeetingUser> = [];
    public onlineUsersSubject: Subject<Array<IMeetingUser>> = new Subject();
    onlineUsersSubject$ = this.onlineUsersSubject.asObservable();

    public meetingInvitations: Array<IMeetingInvitation> = [];
    public meetingInvitationsSubject: Subject<Array<IMeetingInvitation>> = new Subject();
    meetingInvitationsSubject$ = this.meetingInvitationsSubject.asObservable();

    public acceptedInvitation?: IMeetingInvitation;
    public acceptedInvitationSubject: Subject<IMeetingInvitation> = new Subject();
    acceptedInvitationSubject$ = this.acceptedInvitationSubject.asObservable();

    public hideMeetingsControlOutOfScreenSubject: Subject<boolean> = new Subject();
    hideMeetingsControlOutOfScreenSubject$ = this.hideMeetingsControlOutOfScreenSubject.asObservable();

    previousUrl: string = null;
    currentUrl: string = null;

    meetingAudio: HTMLAudioElement = null;
    audioInterval;

    private eventHandlers = [
        {
            name: eSocketEvents.reconnect,
            eventHandler: () => {
                this.identify();
            }
        },
        {
            name: eSocketEvents.connectedUsers,
            eventHandler: (connectedUsers: Array<IMeetingUser>) => {
                const filteredUsers = connectedUsers.filter((u) => u.roomId !== this.user.roomId && u.organization === this.user.organization);

                this.onlineUsers = filteredUsers;
                this.onlineUsersSubject.next(filteredUsers);

                // Filter out invitations of users that aren't online anymore.
                const filteredInvitations = this.meetingInvitations.filter((invitation) => this.onlineUsers.indexOf(invitation.inviter) === -1);
                this.meetingInvitations = filteredInvitations;
                this.meetingInvitationsSubject.next(this.meetingInvitations);
            }
        },
        {
            name: eSocketEvents.updateUserState,
            eventHandler: (connectedUser: IMeetingUser) => {
                this.updateUserState(connectedUser);
            }
        },
        {
            name: eSocketEvents.identityAccepted,
            eventHandler: (identifiedUser: IMeetingUser) => {
                this.user = identifiedUser;
                this.userSubject.next(this.user);

                this.socket.emit(eSocketEvents.listConnectedUsers);
            }
        },
        {
            name: eSocketEvents.invitationSent,
            eventHandler: (invitation: IMeetingInvitation) => {
                const inviter = this.onlineUsers.filter((user) => user.roomId === invitation.inviter.roomId)[0];
                if (!inviter) {
                    // TODO: Probably communicate back to component to display an alert that the user is not available anymore.
                    return;
                }

                const existingInvitations = this.meetingInvitations.filter((i) => i.inviter.roomId === inviter.roomId);
                if (existingInvitations.length > 0) {
                    existingInvitations.forEach((i) => {
                        const index = this.meetingInvitations.indexOf(i);
                        this.meetingInvitations[index] = invitation;
                    });
                } else {
                    this.meetingInvitations.push(invitation);
                }

                this.playMeetingAudioNotification();
                this.meetingInvitationsSubject.next(this.meetingInvitations);
            }
        },
        {
            name: eSocketEvents.invitationRejected,
            eventHandler: (inviteeRoomId: string) => {
                const invitationIndex = this.meetingInvitations.findIndex((invitation) => invitation.invitees.some((i) => i.roomId === inviteeRoomId));

                if (invitationIndex >= 0) {
                    this.meetingInvitations.splice(invitationIndex, 1);
                }
            }
        },
        {
            name: eSocketEvents.meetingNegotiated,
            eventHandler: (invitation: IMeetingInvitation) => {
                const index = this.meetingInvitations.findIndex((i) => i.inviter.roomId === invitation.inviter.roomId);
                // && i.invitees.some((invitee) => invitation.invitees.some((targetInvitee) => invitee.roomId === targetInvitee.roomId)));
                if (index > -1) {
                    this.meetingInvitations[index].inMeeting = true;
                    this.meetingInvitations[index].jitsiRoomId = invitation.jitsiRoomId;
                    this.meetingInvitationsSubject.next(this.meetingInvitations);
                }

                if (!invitation.alreadyAttendingUserRoomIds.some((r) => r === this.user.roomId)) {
                    invitation.alreadyAttendingUserRoomIds.push(this.user.roomId);
                }

                this.acceptedInvitation = invitation;
                this.acceptedInvitationSubject.next(this.acceptedInvitation);

                this.socket.emit(eSocketEvents.userJoinedMeeting, { userRoomId: this.user.roomId, invitation: invitation });
                this.changeState(eMeetingUserState.occupied);
            }
        },
        {
            name: eSocketEvents.invitationCancelled,
            eventHandler: (invitation: IMeetingInvitation) => {
                const index = this.meetingInvitations.findIndex((i) => i.inviter.roomId === invitation.inviter.roomId);
                if (index > -1) {
                    this.meetingInvitations.splice(index, 1);
                    this.meetingInvitationsSubject.next(this.meetingInvitations);
                    this.stopMeetingAudioNotification();
                }
            }
        },
        {
            name: eSocketEvents.userLeftMeeting,
            eventHandler: (data: any) => {
                const invitation: IMeetingInvitation = data.invitation;
                const leavingUserRoomId = data.userRoomId;

                const index = this.meetingInvitations.findIndex((i) => i.jitsiRoomId === invitation.jitsiRoomId && i.alreadyAttendingUserRoomIds.some((roomId) => roomId === leavingUserRoomId));
                if (index > -1) {
                    const alreadyAttendingUserRoomIdsIndex = this.meetingInvitations[index].alreadyAttendingUserRoomIds.indexOf(leavingUserRoomId);
                    if (alreadyAttendingUserRoomIdsIndex > -1) {
                        this.meetingInvitations[index].alreadyAttendingUserRoomIds.splice(alreadyAttendingUserRoomIdsIndex, 1);
                    }

                    const inviteesIndex = this.meetingInvitations[index].invitees.findIndex((invitee) => invitee.roomId === leavingUserRoomId);
                    if (inviteesIndex > -1) {
                        this.meetingInvitations[index].invitees.splice(inviteesIndex, 1);
                    }

                    // If inviter is leaving, we just mark another person as inviter
                    if (this.meetingInvitations[index].inviter.roomId === leavingUserRoomId) {
                        this.meetingInvitations[index].inviter = this.meetingInvitations[index].invitees.splice(0, 1)[0];
                    }

                    this.meetingInvitationsSubject.next(this.meetingInvitations);
                }
            }
        },
        {
            name: eSocketEvents.userJoinedMeeting,
            eventHandler: (data: any) => {
                const invitation: IMeetingInvitation = data.invitation;
                const joiningUserRoomId = data.userRoomId;

                const index = this.meetingInvitations.findIndex((i) => i.jitsiRoomId === invitation.jitsiRoomId);
                if (index > -1) {
                    if (!this.meetingInvitations[index].alreadyAttendingUserRoomIds.some((r) => r === joiningUserRoomId)) {
                        this.meetingInvitations[index].alreadyAttendingUserRoomIds.push(joiningUserRoomId);
                    }
                    this.meetingInvitationsSubject.next(this.meetingInvitations);
                }
            }
        },
        {
            name: eSocketEvents.forceLogout,
            eventHandler: () => {
                const organizationKey = this.oauthStorageAdapter.getCurrentOrganizationKey();
                this.authenticationService.logout().subscribe(
                    (result) => {
                        OAuthStorageAdapter.clearStorage();
                        this.router.navigate(["/login", organizationKey]);
                    },
                    (err) => {
                        OAuthStorageAdapter.clearStorage();
                        this.router.navigate(["/login", organizationKey]);
                    }
                );
            }
        },
        {
            name: eSocketEvents.usersAttendedMeeting,
            eventHandler: (data: any) => {
                // const filteredInvitations = this.meetingInvitations.filter(
                //     (invitation) => ((invitation.inviter.roomId !== data.inviter.roomId || invitation.inviter.roomId !== data.invitee.roomId) && invitation.jitsiRoomId === undefined) || invitation.jitsiRoomId === ""
                // );
                // this.meetingInvitations = filteredInvitations;
                // this.meetingInvitationsSubject.next(this.meetingInvitations);
            }
        }
    ];

    constructor(private socket: Socket, private oauthStorageAdapter: OAuthStorageAdapter, private authenticationService: AuthenticationService, private router: Router, private location: Location) {
        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        if (auth) {
            this.user = {
                email: auth.email,
                accessToken: auth.accessToken,
                organization: auth.selectedOrganization.id
            };
        }

        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
        });

        this.meetingAudio = new Audio("/assets/meeting.mp3");
        this.meetingAudio.load();

        this.registerEventHandlers();
    }

    identify() {
        if (this.socket.ioSocket.connected === false) {
            this.socket.connect();
        }

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        if (auth) {
            this.user = {
                email: auth.email,
                accessToken: auth.accessToken,
                organization: auth.selectedOrganization.id
            };
            this.socket.emit(eSocketEvents.identify, this.user);
        }
    }

    public disconnectSocket() {
        this.socket.disconnect();
        this.user = undefined;
    }

    inviteMeetingUser(meetingUser: IMeetingUser) {
        let invitation: IMeetingInvitation = {
            inviter: this.user,
            invitees: new Array(meetingUser),
            inMeeting: this.acceptedInvitation === undefined || this.acceptedInvitation.jitsiRoomId === undefined || this.acceptedInvitation.jitsiRoomId === "" ? false : true,
            jitsiRoomId: this.acceptedInvitation === undefined || this.acceptedInvitation.jitsiRoomId === undefined || this.acceptedInvitation.jitsiRoomId === "" ? "" : this.acceptedInvitation.jitsiRoomId,
            alreadyAttendingUserRoomIds: new Array()
        };

        const existingInvitations = this.meetingInvitations.filter((i) => i.inviter.roomId === this.user.roomId || i.invitees.some((invitee) => invitee.roomId === this.user.roomId));
        if (existingInvitations.length > 0) {
            // If invited meeting user is the old inviter, we change the inviter to the actual inviting user
            if (existingInvitations[0]?.inviter?.userId === meetingUser.userId) {
                existingInvitations[0].inviter = this.user;
            } else {
                existingInvitations[0].invitees.push(meetingUser);
            }

            invitation = existingInvitations[0];

            const index = this.meetingInvitations.indexOf(invitation);
            if (index > -1) {
                this.meetingInvitations[index] = invitation;
            }
        } else {
            this.meetingInvitations.push(invitation);
        }

        this.meetingInvitationsSubject.next(this.meetingInvitations);

        this.socket.emit(eSocketEvents.invitationSent, invitation);
    }

    acceptInvitation(meetingInvitation: IMeetingInvitation) {
        const meetingsInParticipation = this.meetingInvitations.filter((i) => i.jitsiRoomId !== undefined && i.jitsiRoomId !== "");
        this.stopMeetingAudioNotification();
        this.socket.emit(eSocketEvents.invitationAccepted, meetingInvitation);
    }

    rejectInvitation(meetingInvitation: IMeetingInvitation) {
        const index = this.meetingInvitations.indexOf(meetingInvitation);
        if (index > -1) {
            this.stopMeetingAudioNotification();
            this.meetingInvitations.splice(index, 1);
        }

        this.socket.emit(eSocketEvents.invitationRejected, meetingInvitation);
    }

    cancelInvitation(meetingInvitation: IMeetingInvitation) {
        const index = this.meetingInvitations.indexOf(meetingInvitation);
        if (index > -1) {
            this.meetingInvitations.splice(index, 1);
        }

        this.socket.emit(eSocketEvents.invitationCancelled, meetingInvitation);
    }

    changeState(state: eMeetingUserState) {
        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        if (auth) {
            this.socket.emit(eSocketEvents.changeState, { accessToken: auth.accessToken, state: state });
        }
    }

    meetingEnded(jitsiRoomId: string) {
        const index = this.meetingInvitations.findIndex((invitation) => invitation.jitsiRoomId === jitsiRoomId);
        if (index > -1) {
            this.socket.emit(eSocketEvents.userLeftMeeting, { userRoomId: this.user.roomId, invitation: this.meetingInvitations[index] });

            this.meetingInvitations.splice(index, 1);
            this.meetingInvitationsSubject.next(this.meetingInvitations);

            this.acceptedInvitation.jitsiRoomId = "";
            this.acceptedInvitation.inMeeting = false;
            this.acceptedInvitationSubject.next(this.acceptedInvitation);
        }

        this.changeState(eMeetingUserState.available);
        // this.router.navigate([this.previousUrl]); => we only do this when meeting close button has been pressed
    }

    private registerEventHandlers() {
        this.eventHandlers.forEach((socketEvent) => {
            this.socket.on(socketEvent.name, socketEvent.eventHandler);
        });
    }

    private updateUserState(user: IMeetingUser) {
        const index = this.onlineUsers.findIndex((u) => u.roomId === user.roomId);

        if (index > -1) {
            this.onlineUsers[index].state = user.state;
            this.onlineUsersSubject.next(this.onlineUsers);
        }
    }

    // private webinarStarted(): boolean {
    //     // TODO: Currently this method always returns true for demonstration purposes. Change to according query,
    //     // TODO: when the webinar has really started.
    //     return true;
    // }

    playMeetingAudioNotification() {
        this.audioInterval = setInterval(() => {
            // this.meetingAudio.play();
        }, 800);
    }

    stopMeetingAudioNotification() {
        clearInterval(this.audioInterval);
    }

    hideMeetingsControlOutOfScreen() {
        this.hideMeetingsControlOutOfScreenSubject.next(true);
    }
}
