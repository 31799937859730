import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { IOrganizationSettings, OAuthStorageAdapter } from "src/app/OAuthStorageAdapter";
import { MeetingsControlService } from "src/app/services/meetings-control.service";
import { BaseComponent } from "../BaseComponent";

declare var JitsiMeetExternalAPI;

@Component({
    selector: "app-meeting",
    templateUrl: "./meeting.component.html",
    styleUrls: ["./meeting.component.scss"]
})
export class MeetingComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public organizationSettings: IOrganizationSettings;

    public roomname: string;
    private username: string;
    private api: any;

    constructor(private route: ActivatedRoute, private router: Router, private meetingsControlService: MeetingsControlService, private oauthStorageAdapter: OAuthStorageAdapter) {
        super();

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();

        this.roomname = this.route.snapshot.paramMap.get("room");
        this.username = this.route.snapshot.paramMap.get("username").replace("_", " ");

        if (!this.roomname || !this.username) {
            this.router.navigate(["/"]);
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.createMeeting(
            this.username,
            this.roomname,
            () => {},
            () => {
                this.meetingsControlService.meetingEnded(this.roomname);
                this.router.navigate(["/reception"]);
            },
            (message) => {
                this.api.executeCommand("toggleChat");
                // this.meetingsControlService.hideMeetingsControlOutOfScreen(); => GMP-78: now the box is right - we dont need to close it when chatting
            },
            (message) => {
                // this.meetingsControlService.hideMeetingsControlOutOfScreen(); => GMP-78: now the box is right - we dont need to close it when chatting
            }
        );
    }

    createMeeting(name, room, handler, readyToCloseHandler, incomingMessageHandler, outgoingMessageHandler) {
        const domain = "prod.jitsi.gsk.infillqa.com";
        const options = {
            roomName: room,
            width: "100%",
            height: "100%",
            parentNode: document.querySelector("#jitsi"),
            configOverwrite: {
                startWithVideoMuted: true,
                disableInviteFunctions: true
            },
            interfaceConfigOverwrite: {
                DEFAULT_BACKGROUND: "#00000000",
                SHOW_JITSI_WATERMARK: false,
                MOBILE_APP_PROMO: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
                TOOLBAR_BUTTONS: [
                    "microphone",
                    "camera",
                    "closedcaptions",
                    "desktop",
                    "fullscreen",
                    "fodeviceselection",
                    "hangup",
                    "chat",
                    "etherpad",
                    "settings",
                    "raisehand",
                    "videoquality",
                    "filmstrip",
                    // "invite",
                    "feedback",
                    "shortcuts",
                    "tileview",
                    "download",
                    "help",
                    "e2ee"
                ],
                SETTINGS_SECTIONS: ["devices", "language", "calendar"]
            },
            userInfo: {
                displayName: name
            },
            onload: handler,
            readyToClose: readyToCloseHandler,
            incomingMessage: incomingMessageHandler,
            outgoingMessage: outgoingMessageHandler
        };

        options.userInfo.displayName = name;
        options.onload = handler;

        options.readyToClose = readyToCloseHandler;

        options.incomingMessage = incomingMessageHandler;
        options.outgoingMessage = outgoingMessageHandler;

        this.api = new JitsiMeetExternalAPI(domain, options);
        this.api.addEventListener("readyToClose", readyToCloseHandler);
        this.api.addEventListeners({ incomingMessage: incomingMessageHandler, outgoingMessage: outgoingMessageHandler });

        this.api.executeCommand("subject", " ");
    }

    closeMeeting() {
        this.api.dispose();
        this.api = null;

        const jitsiContainer = <HTMLDivElement>document.getElementById("jitsi");
        jitsiContainer.parentNode.removeChild(jitsiContainer);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.closeMeeting();
        this.meetingsControlService.meetingEnded(this.roomname);
    }
}
