<div class="app-registration" *ngIf="showRegistrationFormView">
    <div class="company-logo">
        <img src="assets/logo.png" />
    </div>
    <form name="form" #f="ngForm">
        <div class="cross-validation">
            <div class="form-group">
                <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                <label>{{ "registration.labels.first_name" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                    <p>{{ "registration.validation.first_name" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                <label>{{ "registration.labels.last_name" | translate }}</label>
                <i class="form-group__bar"></i>
                <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                    <p>{{ "registration.validation.last_name" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                <label>{{ "registration.labels.email" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                    <p>{{ "registration.validation.email" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input id="password" name="password" #passwordInput="ngModel" [(ngModel)]="this.registration.password" type="password" class="form-control" required />
                <label>{{ "registration.labels.password" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="passwordInput.errors?.required && (passwordInput.dirty || passwordInput.touched)">
                    <p>{{ "registration.validation.password" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <input id="passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm" [(ngModel)]="this.registration.passwordConfirm" type="password" class="form-control" required />
                <label>{{ "registration.labels.password_confirm" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="!(this.registration.passwordConfirm === this.registration.password)">
                    <p>{{ "registration.validation.password_confirm_mismatch" | translate }}</p>
                </div>
            </div>

            <div class="form-group" *ngIf="breakoutGroups.length > 0">
                <select id="selectedBreakoutGroupId" name="selectedBreakoutGroupId" [(ngModel)]="selectedBreakoutGroupId" class="form-control">
                    <option [ngValue]="undefined"> - </option>
                    <option *ngFor="let group of breakoutGroups" [ngValue]="group._id">{{group.name}}</option>
                </select>
                <label>{{ "registration.labels.breakoutgroup" | translate }}</label>
            </div>

            <div class="form-group form-group--float form-group--centered">
                <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                <label id="agbAcceptLabel" for="agbAccepted">{{ "registration.labels.accept" | translate }}</label>
                <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                    <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                </div>
            </div>

            <div class="form-group">
                <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
            </div>

            <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                {{ "registration.buttons.register" | translate }}
            </button>
        </div>
    </form>
</div>

<div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
    <h4>{{ "registration.titles.registration_done" | translate }}</h4>
    <p>{{ "registration.paragraphs.registration_successful" | translate }}</p>
</div>
