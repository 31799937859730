<div class="container">
    <header>
        <img src="assets/img/landingpages/post_asco/header.png" />
    </header>
    <article>
        <h1><span class="ascoOrange">Best of Congress Webinar:</span><br/>Neueste Entwicklungen in der Therapie des Ovarial- und des Endometriumkarzinoms</h1>
        <h1>29. Juni, 17:00 Uhr – 18:00 Uhr</h1>
        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="password" name="password" #passwordInput="ngModel" [(ngModel)]="this.registration.password" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="passwordInput.errors?.required && (passwordInput.dirty || passwordInput.touched)">
                            <p>{{ "registration.validation.password" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm" [(ngModel)]="this.registration.passwordConfirm" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password_confirm" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="!(this.registration.passwordConfirm === this.registration.password)">
                            <p>{{ "registration.validation.password_confirm_mismatch" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group" *ngIf="breakoutGroups.length > 0">
                        <select id="selectedBreakoutGroupId" name="selectedBreakoutGroupId" [(ngModel)]="selectedBreakoutGroupId" class="form-control">
                            <option [ngValue]="undefined"> - </option>
                            <option *ngFor="let group of breakoutGroups" [ngValue]="group._id">{{group.name}}</option>
                        </select>
                        <label>{{ "registration.labels.breakoutgroup" | translate }}</label>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted">{{ "registration.labels.accept" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>{{ "registration.paragraphs.registration_successful" | translate }}</p>
        </div>

        <section>
            <h1 class="ascoOrange">17:00 Plenarsitzung</h1>

            <ul class="mt-3 mb-3">
                <li>
                    <span class="ascoOrange"><b>Begrüßung</b></span><br/>
                    <b>Prof. Dr Barbara Schmalfeldt</b><br/>
                    UKE Hamburg, Klinik und Poliklinik für Gynäkologie
                </li>

                <li>
                    <span class="ascoOrange"><b>Konferenz-Highlights zur Therapie des Ovarialkarzinoms</b></span><br/>
                    <b>PD Dr. Beyhan Ataseven</b><br/>
                    KEM Evang. Kliniken Essen-Mitte
                </li>

                <li>
                    <span class="ascoOrange"><b>Konferenz-Highlights zur Therapie des Endometriumkarzinoms</b></span><br/>
                    <b>PD Dr. Marco Johannes Battista</b><br/>
                    Universitätsmedizin der Johannes Gutenberg-Universität Mainz
                </li>

                <li>
                    <span class="ascoOrange"><b>Diskussion</b></span>
                </li>
            </ul>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="http://health-ecomm.com/GSK/GSK-Post-ASCO-Agenda.pdf" target="_blank">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Webinar-BoC-Post-ASCO.ics">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <p class="footnote">
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <span style="font-size: 0.85rem;">NX-DE-DST-WCNT-210001 Mai 2021</span>
        </p> 

    </article>
</div>
