import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { IRegistration } from "../../../models/IRegistration";
import { environment } from "../../../../environments/environment";
import { RegistrationService } from "../../../services/registration.service";
import { BaseComponent } from "../../BaseComponent";
import { IOrganizationSettings } from "../../../OAuthStorageAdapter";
import { generatePassword, md5 } from "../../../tools/tools";

declare const clicktracker: any;

@Component({
    selector: "app-studien-informationstag-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"]
})
export class StudienInformationstagCaRegistrationComponent extends BaseComponent implements OnInit, OnDestroy {
    public organizationKey = "studien-informationstag";

    public countdownSeconds = "";
    public countdownMinutes = "";
    public countdownHours = "";
    public countdownDays = "";

    public registration: IRegistration = {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        passwordConfirm: "",
        agbAccepted: false,
        language: environment.defaultLanguage,
        organizationKey: ""
    };

    public enableLogin = true;

    constructor(private registrationService: RegistrationService, protected route: ActivatedRoute, protected router: Router) {
        super();
    }

    ngOnInit(): void {
        this.registration.organizationKey = this.organizationKey;

        const organizationSettings: IOrganizationSettings = environment.organizations.find((org) => org.key === "studien-informationstag");
        if (organizationSettings?.clickTracking) {
            clicktracker.setup(organizationSettings?.clickTracking?.token, organizationSettings?.clickTracking?.endpoint, organizationSettings?.clickTracking?.tenantId);
            clicktracker.click("landingpage_hit");
        }

        if (organizationSettings?.blockLoginUntil) {
            const blockLoginUntil = moment(organizationSettings.blockLoginUntil, environment.blockLoginUntilTimeformat);
            const now = moment();

            if (now.isBefore(blockLoginUntil)) {
                this.enableLogin = false;
            }
        }

        // Countdown sollte sich am "webinarStart" orientieren. Der countdown verschwindet aber bei "blockLoginUntil" - Besprochen mit Thorsten Land!
        const countDownDate = moment(organizationSettings.webinarStart, environment.webinarTimeformat).toDate().getTime();
        const calculateCountdown = () => {
            const now = new Date().getTime();
            const diff = countDownDate - now;

            this.countdownDays = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(2, "0");
            this.countdownHours = String(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
            this.countdownMinutes = String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
            this.countdownSeconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(2, "0");
        };
        calculateCountdown();
        const countDownInterval = setInterval(calculateCountdown, 1000);
    }

    register(): void {
        const random = generatePassword(32);

        this.registration.password = random;
        this.registration.passwordConfirm = random;

        const hashcode = md5(this.registration.email.toLowerCase().trim());
        this.subscriptions.push(
            this.registrationService.register(this.registration).subscribe(
                (result: any) => {
                    this.router.navigate(["/enter", this.organizationKey, hashcode]);
                },
                (err) => {
                    this.router.navigate(["/enter", this.organizationKey, hashcode]);
                }
            )
        );
    }
}
