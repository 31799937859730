<div class="container">
    <article>
        <br/>
        <h1><span class="orange" style="font-size: 1.85rem;">Wer impft mich?</span><br/>Welcher Impfschutz trägt mich bestmöglich durch meine onkologische Therapie?</h1>
        <h1>Donnerstag, den 9. September 2021 um 19:00 Uhr</h1>
        <p>
            In unserem Hybrid-Live-Web-Seminar gehen wir auf die Besonderheiten bei Impfungen von onkologischen Patient*innen unter Berücksichtigung der aktuellen STIKO-Empfehlungen ein.
            Wir ergänzen die Veranstaltung an Hand eines Beispiels aus der Gynäko-Onkologie.<br/>
            Sie haben die Möglichkeit, online oder Vor-Ort in Mannheim teilzunehmen.
        </p>
        <p>
            Diese Veranstaltung richtet sich an Hausärzte*innen, Internisten*innen, Onkologen*innen und onkologisch tätige Fachärzte*innen. 
        </p>
        <p>
            Wir freuen uns auf Ihre Teilnahme.
        </p>

        <div>
            <button class="btn btn-primary btn-block loginButton" (click)="gotoLogin()">
                Webinar Login
            </button>
        </div>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group" *ngIf="breakoutGroups.length > 0">
                        <select id="selectedBreakoutGroupId" name="selectedBreakoutGroupId" [(ngModel)]="selectedBreakoutGroupId" class="form-control">
                            <option *ngFor="let group of breakoutGroups" [ngValue]="group._id">{{group.name}}</option>
                        </select>
                        <label>{{ "registration.labels.participation" | translate }}*</label>
                    </div>

                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="institution" name="institution" #institutionInput="ngModel" [(ngModel)]="this.registration.misc.institution" type="text" class="form-control" required />
                        <label>{{ "registration.labels.institution" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="institutionInput.errors?.required && (institutionInput.dirty || institutionInput.touched)">
                            <p>{{ "registration.validation.institution" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="address" name="address" [(ngModel)]="this.registration.misc.address" type="text" class="form-control" />
                        <label>{{ "registration.labels.address" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group">
                        <input id="postcode" name="postcode" [(ngModel)]="this.registration.misc.postcode" type="text" class="form-control" />
                        <label>{{ "registration.labels.postcode" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group">
                        <input id="city" name="city" [(ngModel)]="this.registration.misc.city" type="text" class="form-control" />
                        <label>{{ "registration.labels.city" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group">
                        <input id="phone" name="phone" [(ngModel)]="this.registration.misc.phone" type="text" class="form-control" />
                        <label>{{ "registration.labels.phone" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>

                    <div class="form-group">
                        <input id="efn" name="efn" [(ngModel)]="this.registration.misc.efn" type="text" class="form-control" />
                        <label>{{ "registration.labels.efn" | translate }}</label>
                        <i class="form-group__bar"></i>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group" *ngIf="selectedBreakoutGroupId === onlineGroupId">
                        <input id="password" name="password" #passwordInput="ngModel" [(ngModel)]="this.registration.password" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="passwordInput.errors?.required && (passwordInput.dirty || passwordInput.touched)">
                            <p>{{ "registration.validation.password" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group" *ngIf="selectedBreakoutGroupId === onlineGroupId">
                        <input id="passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm" [(ngModel)]="this.registration.passwordConfirm" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password_confirm" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="!(this.registration.passwordConfirm === this.registration.password)">
                            <p>{{ "registration.validation.password_confirm_mismatch" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted">{{ "registration.labels.accept" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>{{ "registration.paragraphs.registration_successful" | translate }}</p>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr class="orange strong">
                    <td>19:00</td>
                    <td>Begrüßung</td>
                </tr>
                <tr class="orange strong">
                    <td>19:05</td>
                    <td>Gürtelrose - eine Rose die keiner will</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Frau Dipl.-Med. Brennenstuhl – Live aus Berlin</td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>19:35</td>
                    <td>Impfversorgung von onkologischen Patient*innen und deren familiärem Umfeld</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Frau Prof. Dr. Egerer – Live aus Mannheim </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>20:05</td>
                    <td>Diskussion -  Praktische Umsetzung am Beispiel einer Ovarialkarziom Patientin</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>alle Referenten</td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>20:15</td>
                    <td>Aktiv Pause</td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>20:25</td>
                    <td>Tipps zum Impfmanagement </td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Frau Dipl.-Med. Brennenstuhl – Live aus Berlin </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>20:35</td>
                    <td>Neue Gynäko-Onkologische Therapien und deren praktische Anwendungen</td>
                </tr>
                <tr class="strong">
                    <td></td>
                    <td>Prof. Dr. Bohlmann – Live aus Mannheim</td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td>21:15</td>
                    <td>Abschluss</td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr class="orange strong">
                    <td></td>
                    <td>Die CME-Zertifizierung der Veranstaltung ist beantragt.</td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Einladung-Wer-impft-mich.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Wer-impft-mich.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <p class="footnote">
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <span style="font-size: 0.85rem;">NP-DE-ON-EML-210001</span>
        </p> 

    </article>
</div>
