import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-legal',
  templateUrl: './data-legal.component.html',
  styleUrls: ['./data-legal.component.scss']
})
export class DataLegalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
