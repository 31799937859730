<div id="lecturehall" [style]="'background-image: url(' + organizationSettings.lecturehallBg + ')'">
    <!-- [style.top]="this.webinarPlayerTop" [style.left]="this.webinarPlayerLeft" -->
    <div id="webinarPlayerContainer" [class.meetps-visible]="meetpsVisible">
        <div id="webinarPlayer"></div>
    </div>

    <div id="chatContainer" [class.chat-visible]="chatVisible"  *ngIf="!disable3qsdnChat">
        
    </div>
    <div class="meetps-iframe" [class.meetps-visible]="meetpsVisible">
        <iframe [src]="meetpsUrl"></iframe>
    </div>

    <button *ngIf="!organizationSettings.loginToLectureHall" class="gsk-btn bottom-left-nav-btn" mat-raised-button (click)="toReception()">
        <mat-icon class="back-btn">navigate_before</mat-icon>
        {{ 'lecturehall.buttons.reception' | translate }}
    </button>

    <div class="bottom-right-nav-btn">

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="switchToVideo1()" *ngIf="hasMultipleVideoIds && activeVideo === 2">
            {{ organizationSettings.id3qsdnSwitch1 }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="switchToVideo2()" *ngIf="hasMultipleVideoIds && activeVideo === 1">
            {{ organizationSettings.id3qsdnSwitch2 }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="enterFullscreen()">
            {{ 'lecturehall.buttons.fullscreen' | translate  }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="openFeedback()" *ngIf="organizationSettings.key === 'xevudy'">
            Feedback
        </button>

        <button *ngIf="breakoutGroup && showBreakoutGroup" class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="gotoBreakoutGroup()">
            {{ 'lecturehall.buttons.breakoutGroup' | translate: {name: breakoutGroup.name}  }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="toggleMeetps()">
            {{ 'lecturehall.buttons.meetpsToggle' | translate }}
        </button>

        <button class="gsk-btn" style="margin-left: 25px;" mat-raised-button (click)="toggleChat()" *ngIf="!disable3qsdnChat">
            {{ 'lecturehall.buttons.chatToggle' | translate }}
        </button>
    </div>
</div>

