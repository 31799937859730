<div class="container">
    <header>
        <img src="assets/img/landingpages/studien-informationstag/header.png" style="width: 100%;" />
    </header>
    <article>
        <!-- <h1>
            <span class="strong">Informationstag für Patient*innen:</span><br/>
            <span class="strong orange">IM FOKUS: STUDIEN BEI KREBSERKRANKUNGEN</span>
        </h1>
        <h1>
            <span class="strong">Samstag, 13. November</span><br/>
            <span class="strong">13:00 Uhr – 16:30 Uhr</span>
        </h1> -->

        <br/>

        <p class="text-center">
            Herzlichen Dank für Ihr Interesse an unserer virtuellen Veranstaltung!<br/>

            Wir freuen uns sehr, Ihnen am <span class="strong">Samstag, den 13.11.2021</span> ab 13:00 Uhr Einblicke in das Thema<br/>

            <span class="strong">„Studien bei Krebserkrankungen“</span> zu geben.
        </p>
        <p>
            Wenn Sie dabei sein möchten, können Sie sich am Vortragstag bereits ab 10:00 Uhr hier online mit Ihrer Mailadresse einloggen. Im Anschluss daran gelangen Sie zu unserem <span class="strong">Online-Portal</span> – dort finden am Tag der Veranstaltung alle Vorträge statt. Zudem können Sie sich informieren und finden auch Broschüren zum Download im virtuellen Raum.
        </p>
        
        <h1 class="strong orange">Hier können Sie sich einloggen:</h1>

        <div class="app-registration greyBackground" *ngIf="enableLogin">
            <form name="form" #f="ngForm">
                <div class="cross-validation">

                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted">{{ "registration.labels.accept" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>

                </div>
            </form>
        </div>

        <div class="app-countdown" *ngIf="!enableLogin">
            <div class="clock-container">
                <div class="clock-col">
                  <p class="clock-day clock-timer">
                      {{ countdownDays }}
                  </p>
                  <p class="clock-label">
                    Tage
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-hours clock-timer">
                    {{ countdownHours }}
                  </p>
                  <p class="clock-label">
                    Stunden
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-minutes clock-timer">
                    {{countdownMinutes}}
                  </p>
                  <p class="clock-label">
                    Minuten
                  </p>
                </div>
                <div class="clock-col">
                  <p class="clock-seconds clock-timer">
                    {{countdownSeconds}}
                  </p>
                  <p class="clock-label">
                    Sekunden
                  </p>
                </div>
            </div>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr>
                    <td class="align-top" style="width: 145px;">
                        13:00 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Begrüßung</span><br/>
                        Dr. Irene Wölfel (GSK Pharma Deutschland)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        13:05 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Der Weg zur klinischen Studie – Ein Blick hinter die Kulissen (Präklinik)</span><br/>
                        Hr. Dr. Ludger Langer (GSK Pharma Deutschland)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        13:35 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Die Etappen der klinischen Studien</span><br/>
                        Fr. Dr. Annette Junker-Stein (Klinikum Frankfurt-Höchst)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        14:20 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">1-stündige Pause: <i>Optionale Teilnahme an folgenden Breakout-Sessions:</i></span><br/>
                        Jeweils eine Live-Führung durch die Portale („Wie finde ich mich zurecht, wo finde ich was?“, „Welche Stichwörter eignen sich zur Suche von Studien?“, „Woher weiß ich, welches Zentrum in meiner Nähe teilnimmt?“).
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>






                <tr>
                    <td></td>
                    <td class="align-top" style="width: 145px;">
                        14:20 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Allgemeine Studien-Register wie DRKS und clinical.trial.gov</span><br/>
                        Hr. Dr. Ludger Langer (GSK Pharma Deutschland)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="align-top">
                        14:40 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Studienportal der Stiftung Eierstockkrebs für Patientinnen mit gynäkologischen Tumorerkrankungen</span><br/>
                        Hr. Dr. Robert Armbrust (Charité – Universitätsmedizin Berlin)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td></td>
                    <td class="align-top">
                        15:00 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Studienübersicht der Patientenorganisation AMM-Online für Patient*innen mit Multiplem Myelom</span><br/>
                        Fr. Dr. Sabine Schock, AMM-Online - Arbeitsgemeinschaft Multiples Myelom (Plasmozytom, Morbus Kahler)
                    </td>
                </tr>









                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        15:20 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Der Hürdenlauf der Medikamentenentwicklung in der Onkologie</span><br/>
                        Fr. Dr. Anne Marie Asemissen (Universitätsklinikum Hamburg-Eppendorf)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        15:50 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Beteiligung von Patient*innen in der Krebsforschung aus Sicht einer Patientenorganisation</span><br/>
                        Hr. Markus Wartenberg (Dt. Sarkom-Stiftung)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        16:10 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Diskussionsrunde / Fragenrunde</span><br/>
                        Fr. Andrea Krull (Eierstockkrebs Deutschland e.V.) und Referent*innen der vorherigen Vorträge
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        16:25 Uhr
                    </td>
                    <td colspan="2">
                        <span class="orange strong">Verabschiedung</span><br/>
                        Dr. Irene Wölfel (GSK Pharma Deutschland)
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <!-- <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Einladung_Medizinische_Fortbildung_08.09.2021_virtuell.pdf" target="_blank" onclick="clicktracker.click('ladingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div> -->
                <div class="col-sm-12 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Informationstag-fur-Patientinnen.ics" onclick="clicktracker.click('ladingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <p class="footnote">
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <span style="font-size: 0.85rem;">NP-DE-AOU-PINF-210001 (10/2021)</span>
        </p> 

    </article>
</div>
