<div class="container-bg-image">
    
</div>
<div class="container">
    <!-- <div class="website-element">
        <img src="assets/img/landingpages/cd226/Banner_4a_1920x1080px.png"/>
    </div> -->
    <article>
        <br/>
        <h1><span class="orange" style="font-size: 1.85rem;">Die CD226 Achse – Neue Ansätze in der Immunonkologie bei soliden Tumoren</span></h1>
        <h1>Dienstag, 14. November 2023 ab 17:00 Uhr</h1>
        <p>
            Hiermit laden wir Sie herzlich zu unserer virtuellen GSK Research Session am 14. November 2023 von 17:00 – 18:30 Uhr ein! 
        </p>
        <p>
            Es erwartet Sie ein spannendes klinisch-wissenschaftliches
            Programm rund um die CD226 Achse –
            eine Gruppe von Immuncheckpoints, die neben
            PD-1/PD-L1 eine entscheidende Rolle bei der
            Regulation von Immunantworten in verschiedenen
            soliden Tumoren spielt.
        </p>
        <p>
            Lassen Sie sich in diesem Webinar von namhaften
            Experten alles Wichtige in gebündelter Form zum
            Wirkmechanismus, aktuellen Daten und laufenden
            klinischen Studien im Bereich der CD226 Achse
            erläutern.
        </p>
        <p>
            Die Teilnahme ist kostenlos. Wir freuen uns auf Sie!
        </p>
        <p>
            Ihr GSK Medical Affairs<br/>
            Onkologie Pipeline Team            
        </p>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="efn" name="efn" #efnInput="ngModel" [(ngModel)]="this.registration.misc.efn" type="text" class="form-control" required minlength="15" maxlength="15" pattern="^80276(.*)"  />
                        <label>{{ "registration.labels.efn" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="efnInput.invalid && (efnInput.dirty || efnInput.touched)">
                            <p>Ungültige EFN-Nummer, 15 Ziffern</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="professionalCircle" #professionalCircleInput="ngModel" name="professionalCircle" [(ngModel)]="this.registration.misc.professionalCircle" type="checkbox" required />
                        <label id="professionalCircleLabel" for="professionalCircle" style="margin-left:25px; margin-top:-55px;">Ich bestätige, dass ich Angehörige/r des medizinischen Fachkreis nach §2 HWG bin.</label>
                        <div class="validation-error alert alert-danger" *ngIf="professionalCircleInput.errors?.required && (professionalCircleInput.dirty || professionalCircleInput.touched)">
                            <p>Sie müssen ein Angehörige/r des medizinischen Fachkreis sein.</p>
                        </div>
                    </div>

                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted" style="margin-left:25px; margin-top:-55px;">Ich akzeptiere die Nutzungsbedingungen.</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>Sie müssen die Nutzungsbedingungen akzeptieren.</p>
                        </div>
                    </div>

                    
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button *ngIf="!registrationProcessStarted" class="btn btn-primary btn-block" type="submit" (click)="registerClick()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>Ihre Registrierung war erfolgreich. Sie werden in Kürze eine Bestätigungs-E-Mail erhalten. Bitte bestätigen Sie per Klick auf den Bestätigungslink ihre Anmeldung. Schauen Sie bitte auch im Spam Postfach nach.</p>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr class="orange strong">
                    <td style="width:18%;">&nbsp;Uhrzeit</td>
                    <td>Vortragstitel</td>
                    <td>Referenten</td>
                </tr>
                <tr class="lightgrey">
                    <td>17:00 – 17:05</td>
                    <td>Willkommen & Einführung</td>
                    <td>Martin Schuler</td>
                </tr>
                <tr class="darkgrey">
                    <td>17:05 – 17:20</td>
                    <td>Deep dive into the CD226 axis – Mode of action</td>
                    <td>Tedi Soule</td>
                </tr>
                <tr class="lightgrey">
                    <td>17:20 – 17:35</td>
                    <td>CD226 Achse – Rationale & präklinische Daten</td>
                    <td>Tobias Pukrop</td>
                </tr>
                <tr class="darkgrey">
                    <td>17:35 – 17:55</td>
                    <td>Wo stehen wir aktuell? – Klinische Daten zur CD226 Achse</td>
                    <td>Christian Grohé</td>
                </tr>
                <tr class="lightgrey">
                    <td>17:55 – 18:25</td>
                    <td>What‘s coming next? –</td>
                    <td>Ivan Diaz-Padilla</td>
                </tr>
                <tr class="lightgrey">
                    <td></td>
                    <td>Klinische Studien mit Target CD226 Achse</td>
                    <td>Martin Schuler</td>
                </tr>
                <tr class="lightgrey">
                    <td></td>
                    <td>Mit anschließender Paneldiskussion</td>
                    <td>Konrad Klinghammer</td>
                </tr>
                <tr class="darkgrey">
                    <td>18:25 – 18:30</td>
                    <td>Zusammenfassung</td>
                    <td></td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/cd226/cd226-agenda.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/cd226/cd226.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>

        <p class="footnote">
            
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <!-- TODO update material number -->
            <span style="font-size: 0.85rem;">NX-DE-AOU-WCNT-230001 (10/2023)</span>
        </p> 

    </article>
</div>
