import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";

declare const CometChatWidget: any; // <= .js include in index.html

@Component({
    selector: "app-single-layout",
    templateUrl: "./single-layout.component.html",
    styleUrls: ["./single-layout.component.scss"]
})
export class SingleLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
    public showDisclaimer = false;
    public disclaimerSettings;
    public helpVisible = false;

    private settings: IOrganizationSettings | null = null;
    private organizationKey = "";

    constructor(private oauthStorageAdapter: OAuthStorageAdapter, private oauthService: AuthenticationService, private router: Router) {}

    ngOnDestroy(): void {
        try {
            CometChatWidget.logout();
            document.getElementById("cometchat__widget").remove();
        } catch (exc) {
            // we dont care here
        }
    }

    ngOnInit(): void {
        this.settings = this.oauthStorageAdapter.getOrganizationSettings();
        this.decideToShowDisclaimer();
    }

    ngAfterViewInit() {
        this.oauthService.profile().subscribe((profileResult: any) => {
            if (this.settings?.cometchat && profileResult) {
                CometChatWidget.init({
                    appID: this.settings?.cometchat?.appID,
                    appRegion: this.settings?.cometchat?.appRegion,
                    authKey: this.settings?.cometchat?.authKey
                }).then(
                    () => {
                        const urlArr = this.router.url.split("/");
                        const uiInt = setInterval(() => {
                            try {
                                document.getElementById("cometchat__widget").classList.add("cc_addon_" + urlArr[urlArr.length - 1]);
                                clearInterval(uiInt);
                            } catch (exc) {}
                        }, 500);

                        CometChatWidget.createOrUpdateUser({
                            uid: profileResult._id,
                            name: `${profileResult.firstname} ${profileResult.lastname}`
                        }).then(
                            () => {
                                CometChatWidget.login({
                                    uid: profileResult._id
                                }).then(
                                    () => {
                                        CometChatWidget.launch({
                                            widgetID: this.settings?.cometchat?.widgetID,
                                            docked: this.settings?.cometchat?.docked,
                                            alignment: this.settings?.cometchat?.alignment,
                                            roundedCorners: this.settings?.cometchat?.roundedCorners,
                                            height: this.settings?.cometchat?.height,
                                            width: this.settings?.cometchat?.width
                                        });
                                    },
                                    (error) => {
                                        console.log("User login failed with error:", error);
                                    }
                                );
                            },
                            (error) => {
                                console.log("User createorupdate failed with error:", error);
                            }
                        );
                    },
                    (error) => {
                        console.log("Initialization failed with error:", error);
                    }
                );
            }
        });
    }

    decideToShowDisclaimer() {
        if (this.settings?.postLoginDisclaimer?.show) {
            this.disclaimerSettings = this.settings.postLoginDisclaimer;
            this.organizationKey = this.settings.key;

            const disclaimerAcceptedDateString = localStorage.getItem(`${this.organizationKey}-disclaimer-accepted`);
            if (!disclaimerAcceptedDateString) {
                this.showDisclaimer = true;
                return;
            }

            try {
                const disclaimerAcceptedDate = moment(disclaimerAcceptedDateString);
                if (!disclaimerAcceptedDate.isValid()) {
                    this.showDisclaimer = true;
                    return;
                }

                const timeDiffInMonths = moment().diff(disclaimerAcceptedDate, "months");
                if (timeDiffInMonths >= 1) {
                    this.showDisclaimer = true;
                }
            } catch (exc) {
                this.showDisclaimer = true;
                console.log(exc);
            }
        }
    }

    agreeDisclaimer() {
        localStorage.setItem(`${this.organizationKey}-disclaimer-accepted`, String(moment().format()));
        this.showDisclaimer = false;
    }

    toggleHelp() {
        this.helpVisible = !this.helpVisible;
    }
}
