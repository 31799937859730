import { Component, OnDestroy, OnInit } from "@angular/core";
import { BaseComponent } from "../../BaseComponent";

declare var js3q;

@Component({
    selector: "app-gsktagung",
    templateUrl: "./gsktagung.component.html",
    styleUrls: ["./gsktagung.component.scss"]
})
export class GSKTagungComponent extends BaseComponent implements OnInit, OnDestroy {
    public js3qVideoPlayer: any = null;

    constructor() {
        super();
    }

    ngOnInit(): void {
        const _js3qi = setInterval(() => {
            if (typeof js3q !== "undefined") {
                clearInterval(_js3qi);
                this.js3qVideoPlayer = new js3q({
                    dataid: "7dae9b1c-4886-11ec-b839-0cc47a188158",
                    container: "webinarPlayer"
                });
            }
        }, 10);
    }
}
