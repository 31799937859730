import { Injectable } from "@angular/core";
import { IOAuthLoginResult } from "./models/IOAuthLoginResult";
import { environment } from "../environments/environment";

export interface IOrganizationSettings {
    key: string;
    id3qsdn: string;
    id3qsdn2dn?: string;
    id3qsdnSwitch1?: string;
    id3qsdnSwitch2?: string;
    disable3qsdnChat?: boolean;
    webinarStart: string;
    waitingRoomFeature: boolean;
    panoSwf: string;
    panoXml: string;
    waitingRoomAssets: string;
    lecturehallBg: string;
    meetingBg: string;
    showStatusBox: boolean;
    tawkId: string;
    tawkSuffix?: string;
    postLoginDisclaimer: {
        show: boolean;
        i18nTitleKey?: string;
        i18nContentKey?: string;
        i18nAgreeButtonKey?: string;
    };
    cometchat?: {
        appID: string;
        appRegion: string;
        authKey: string;
        widgetID: string;
        docked: string;
        alignment: string;
        roundedCorners: string;
        height: string;
        width: string;
    };
    meetps?: string;
    losTrackingEndpoint?: string;
    clickTracking?: {
        endpoint?: string;
        token?: string;
        tenantId?: string;
    };
    blockLoginUntil?: string;
    loginDateTextFormat?: string;
    hideBreakoutButtonUntil?: string;
    i18nBlockedLoginTextKey?: string;
    loginToLectureHall?: boolean;
}

@Injectable()
export class OAuthStorageAdapter {
    static readonly CURRENT_OAUTH_RESULT = "current_oauth_result";
    static readonly CURRENT_OAUTH_ORGANIZATION = "current_oauth_organization";

    public static clearStorage(): void {
        sessionStorage.clear();
    }

    getCurrentOAuthResult(): IOAuthLoginResult | null {
        const storedAuthorization = sessionStorage.getItem(OAuthStorageAdapter.CURRENT_OAUTH_RESULT);

        if (storedAuthorization === null) {
            return null;
        }

        try {
            return JSON.parse(storedAuthorization);
        } catch (error) {
            return null;
        }
    }

    setCurrentOauthResult(oauthResult: IOAuthLoginResult) {
        sessionStorage.setItem(OAuthStorageAdapter.CURRENT_OAUTH_RESULT, JSON.stringify(oauthResult));
    }

    setCurrentOrganizationKey(key: string) {
        sessionStorage.setItem(OAuthStorageAdapter.CURRENT_OAUTH_ORGANIZATION, key);
    }

    getCurrentOrganizationKey() {
        return sessionStorage.getItem(OAuthStorageAdapter.CURRENT_OAUTH_ORGANIZATION);
    }

    getOrganizationSettings(): IOrganizationSettings | null {
        const auth = this.getCurrentOAuthResult();
        return environment.organizations.find((org) => org.key === auth.selectedOrganization.key);
    }
}
