import { Component, OnDestroy, OnInit } from "@angular/core";
import { IRegistration } from "../../../models/IRegistration";
import { IRegistrationConfirmation } from "../../../models/IRegistrationConfirmation";
import { environment } from "../../../../environments/environment";
import { RegistrationService } from "../../../services/registration.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IBreakoutGroup } from "../../../models/IBreakoutGroup";
import { BaseComponent } from "../../BaseComponent";
import { generatePassword, md5 } from "../../../tools/tools";

declare const clicktracker: any;

@Component({
    selector: "app-cd226-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"]
})
export class CD226RegistrationComponent extends BaseComponent implements OnInit, OnDestroy {
    public organizationKey = "cd226";
    public registrationProcessStarted = false;

    public registration: IRegistration = {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        passwordConfirm: "",
        agbAccepted: false,
        language: environment.defaultLanguage,
        organizationKey: "",
        misc: {
            efn: "",
            professionalCircle: false
        }
    };

    public showRegistrationFormView: boolean = true;
    public showRegistrationSuccessfulView: boolean = false;
    public breakoutGroups: IBreakoutGroup[] = [];
    public selectedBreakoutGroupId: string;

    constructor(private registrationService: RegistrationService, protected route: ActivatedRoute, protected router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params) => {
                const token = params.get("token");
                if (token) {
                    this.confirmRegistration(token);
                }
            })
        );

        this.registration.organizationKey = this.organizationKey;

        this.subscriptions.push(
            this.registrationService.breakoutgroups(this.organizationKey).subscribe((result) => {
                this.breakoutGroups = result.filter((group) => group.registrations < group.memberLimit || group.memberLimit <= 0);
                for (const group of this.breakoutGroups) {
                    if (group.name.trim().toLowerCase() === "online") {
                        this.selectedBreakoutGroupId = group._id;
                    }
                }
            })
        );

        const organizationSettings: any = environment.organizations.find((org) => org.key === this.organizationKey);
        if (organizationSettings?.clickTracking) {
            clicktracker.setup(organizationSettings?.clickTracking?.token, organizationSettings?.clickTracking?.endpoint, organizationSettings?.clickTracking?.tenantId);
            clicktracker.click("landingpage_hit");
        }
    }

    registerClick() {
        if (this.registrationProcessStarted === false) {
            this.registrationProcessStarted = true;
            this.register();
        }
    }

    register(): void {
        if (this.selectedBreakoutGroupId) {
            this.registration.misc.breakoutGroupToRegister = this.selectedBreakoutGroupId;
        }

        const random = generatePassword(32);

        this.registration.password = random;
        this.registration.passwordConfirm = random;

        this.subscriptions.push(
            this.registrationService.register(this.registration).subscribe({
                next: (result: any) => {
                    this.showRegistrationFormView = false;
                    this.showRegistrationSuccessfulView = true;
                    this.registrationProcessStarted = false;
                },
                error: () => {
                    this.registrationProcessStarted = false;
                }
            })
        );
    }

    confirmRegistration(token: string): void {
        const registrationConfirmation: IRegistrationConfirmation = {
            token: token,
            language: "en"
        };
        this.subscriptions.push(
            this.registrationService.confirmRegistration(registrationConfirmation).subscribe((result) => {
                this.router.navigate(["/login", this.organizationKey]);
            })
        );
    }

    gotoLogin(): void {
        this.router.navigate(["/login", this.organizationKey]);
    }
}
