import { Component, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";

@Component({ selector: "", template: `<div></div>` })
export abstract class BaseComponent implements OnDestroy {
    public subscriptions: Subscription[] = [];

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
