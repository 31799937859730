<div class="container-bg-image">
    
</div>
<div class="container">
    <div class="website-element">
        <img src="assets/img/landingpages/womens-cancer/230713_womens_cancer_flyer_header.jpg"/>
    </div>
    <article>
        <br/>
        <h1><span class="orange" style="font-size: 1.85rem;">Let's Talk about Women&apos;s Cancer</span></h1>
        <h1>Samstag, 09. September 2023 ab 11:00 Uhr</h1>
        <p>
            Sehr geehrte Dame, sehr geehrter Herr,
        </p>
        <p>
            die Therapielandschaft in der gynäkologischen Onkologie hat in den letzten Jahren eine Wandlung erfahren. Zielgerichtete Therapien gewinnen zunehmend an Bedeutung und molekulare Marker bieten Unterstützung, die geeigneten Substanzen in der Therapie von Ovarialkarzinom und Endometriumkarzinom auszuwählen.</p>
            <p>So ist die PARP-Inhibition fester Bestandteil der Erstlinien-Erhaltungstherapie des Ovarialkarzinoms,<sup>1</sup> und die Biomarker gestützte Einordnung des Endometriumkarzinoms ist u.a. Basis für eine Therapie mit Immuntherapeutika.<sup>2</sup></p>
                <p>Diese Themen werden unter der Moderation von Dr. Friedrich Overkamp gemeinsam mit Experten aus Gynäkologischer Onkologie, internistischer Onkologie und Molekularer Pathologie untereinander und mit Ihnen diskutiert.</p>
                    <p>Ermuntern wollen wir Sie, sich mit Ihren Erfahrungen und Patienten-Fällen über den Chat an der Diskussion einzubringen.

        </p>
        <p>Ihr GSK Women‘s Cancer Team
        </p>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="efn" name="efn" #efnInput="ngModel" [(ngModel)]="this.registration.misc.efn" type="text" class="form-control" required minlength="15" maxlength="15" pattern="^80276(.*)"  />
                        <label>{{ "registration.labels.efn" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="efnInput.invalid && (efnInput.dirty || efnInput.touched)">
                            <p>Ungültige EFN-Nummer, 15 Ziffern</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted" style="margin-left:25px; margin-top:-55px;">Ich bestätige, dass ich Angehörige/r des medizinischen Fachkreis nach §2 HWG bin.</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>Sie müssen ein Angehörige/r des medizinischen Fachkreis sein.</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button *ngIf="!registrationProcessStarted" class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>Ihre Registrierung war erfolgreich. Sie werden in Kürze eine Bestätigungs-E-Mail erhalten. </p>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr class="orange strong">
                    <td style="width:20%;">11:00 – 11:15 Uhr</td>
                    <td>Einleitung und Vorstellung</td>
                </tr>
                <tr class="orange strong">
                    <td style="vertical-align: top;">11:15 – 13:00 Uhr</td>
                    <td>
                        Impulse und Diskussionsgrundlage zur Podiumsdiskussion
                        <ul>
                            <li>PARP-Inhibitoren zur Erstlinien-Erhaltungstherapie des Ovarialkarzinoms</li>
                            <li>Welche Rolle spielen Biomarker im EC & OC? Wann und wie soll auf diese getestet werden?</li>
                            <li>Patientinnen-individualisierte Therapie? Monotherapie versus</li>
                            <li>Kombinationstherapie beim OC, bei welcher Patientin können wir deeskalieren?</li>
                            <li>PD-1-Inhibitoren in der Therapie des fortgeschrittenen/rezidiviertenEndometriumkarzinoms</li>
                            <li>Was gilt es zu beachten beim Einsatz von Immuncheckpoint-Inhibitoren?</li>
                            <li>Patientenfälle</li>
                        </ul>
                    </td>
                </tr>
                <tr class="orange strong">
                    <td>13:00 Uhr</td>
                    <td>Ende der Veranstaltung</td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/womens-cancer/GSK-Womens-Cancer-Agenda.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/womens-cancer/GSK-Womens-Cancer.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <ol style="padding-left:20px;">
            <li style="font-size: 1.15rem;">S3-Leitlinie Diagnostik, Therapie und Nachsorge maligner Ovarialtumoren, Langversion 5.1, 2022 <a href="https://www.leitlinienprogramm-onkologie.de/leitlinien/ovarialkarzinom" target="_blank">https://www.leitlinienprogramm-onkologie.de/leitlinien/ovarialkarzinom abgerufen am 27.06.2023</a></li>
            <li style="font-size: 1.15rem;">Leitlinienprogramm Onkologie; Deutsche Krebsgesellschaft, Deutsche Krebshilfe, AWMR: S3-Leitlinie zum Endometriumkarzinom, 2.0, 2022, <a href="https://www.leitlinienprogrammonkologie.de/leitlinien/endometriumkarzinom" target="_blank">https://www.leitlinienprogrammonkologie.de/leitlinien/endometriumkarzinom;</a></li>
        </ol>

        <p class="footnote">
            
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <!-- TODO update material number -->
            <span style="font-size: 0.85rem;">PM-DE-DST-LBND-210008 (07/2023)</span>
        </p> 

    </article>
</div>
