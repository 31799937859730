import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as moment from "moment";
import { OrganizationBaseComponent } from "../OrganizationBaseComponent";
import { AuthenticationService } from "../../services/authentication.service";
import { ActivatedRoute, Router } from "@angular/router";
import { IOAuthLoginResult } from "../../models/IOAuthLoginResult";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent extends OrganizationBaseComponent implements OnInit {
    public loginEmail: string = "";
    public loginPassword: string = "";

    public enableLogin = true;
    public loginEnabledDate = "";

    public i18nBlockedLoginTextKey = "login.loginBlocked";
    private organizationSettings?: IOrganizationSettings;

    @ViewChild("loginPasswordField", { static: false }) loginPasswordField: ElementRef;

    constructor(private authenticationService: AuthenticationService, protected router: Router, private oauthStorageAdapter: OAuthStorageAdapter, protected route: ActivatedRoute) {
        super(router, route);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.organizationSettings = environment.organizations.find((org) => org.key === this.organizationKey);
        if (this.organizationSettings?.blockLoginUntil) {
            const blockLoginUntil = moment(this.organizationSettings.blockLoginUntil, environment.blockLoginUntilTimeformat);
            const now = moment();

            if (now.isBefore(blockLoginUntil)) {
                this.enableLogin = false;
                this.loginEnabledDate = blockLoginUntil.format(this.organizationSettings.loginDateTextFormat);
            }
        }

        if (this.organizationSettings?.i18nBlockedLoginTextKey) {
            this.i18nBlockedLoginTextKey = this.organizationSettings.i18nBlockedLoginTextKey;
        }
    }

    onEnterEmail(): void {
        this.loginPasswordField.nativeElement.focus();
    }

    onEnterPassword(): void {
        this.login();
    }

    login(): void {
        this.subscriptions.push(
            this.authenticationService.login(this.loginEmail, this.loginPassword, this.organizationKey).subscribe((result: IOAuthLoginResult) => {
                this.oauthStorageAdapter.setCurrentOauthResult(result);
                this.oauthStorageAdapter.setCurrentOrganizationKey(this.organizationKey);

                if (this.organizationSettings?.loginToLectureHall) {
                    this.router.navigate(["/lecturehall"]);
                } else {
                    this.router.navigate(["/reception"]);
                }
            })
        );
    }

    gotoRegistration(): void {
        if (this.organizationKey === "wer-impft-mich") {
            this.router.navigate(["/landingpage/", this.organizationKey]);
            return;
        }
        this.router.navigate(["/registration/", this.organizationKey]);
    }
}
