import { Component, OnDestroy, OnInit, AfterViewInit } from "@angular/core";
import { IMeetingUser } from "../../models/IMeetingUser";
import { IMeetingInvitation } from "../../models/IMeetingInvitation";
import { MeetingsControlService } from "../../services/meetings-control.service";
import { Router } from "@angular/router";
import { BaseComponent } from "../BaseComponent";
import { FormControl } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IOrganizationSettings, OAuthStorageAdapter } from "src/app/OAuthStorageAdapter";

declare var js3q;
declare const CometChatWidget: any; // <= .js include in index.html

@Component({
    selector: "app-meetings-control",
    templateUrl: "./meetings-control.component.html",
    styleUrls: ["./meetings-control.component.scss"]
})
export class MeetingsControlComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    meetingsControlVisible: boolean = false;
    meetingsControlOutOfScreen: boolean = false;
    webinarStreamVisible: boolean = false;
    showChatIcon: boolean = false;

    userFocussed: boolean = false;
    public statusControl = new FormControl();
    public meetingUsers: Array<IMeetingUser> = [];
    public meetingInvitations: Array<IMeetingInvitation> = [];
    public user: IMeetingUser = {
        email: "",
        firstName: "",
        lastName: "",
        accessToken: "",
        roomId: "",
        organization: "",
        userId: ""
    };
    public js3qVideoPlayer: any = null;
    public organizationSettings: IOrganizationSettings;

    constructor(private meetingsControlService: MeetingsControlService, private router: Router, private authenticationService: AuthenticationService, private oauthStorageAdapter: OAuthStorageAdapter) {
        super();

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
        if (this.organizationSettings.cometchat) {
            this.showChatIcon = true;
        }
    }

    ngOnInit(): void {
        this.meetingInvitations = this.meetingsControlService.meetingInvitations;

        this.subscriptions.push(
            this.meetingsControlService.onlineUsersSubject$.subscribe((onlineUsers) => {
                this.meetingUsers = onlineUsers;
            })
        );
        this.subscriptions.push(
            this.meetingsControlService.meetingInvitationsSubject$.subscribe((invitations) => {
                this.meetingInvitations = invitations;
            })
        );
        this.subscriptions.push(
            this.meetingsControlService.userSubject$.subscribe((u) => {
                this.user = u;
                this.setStatusForm(this.user.state);
            })
        );
        this.subscriptions.push(
            this.meetingsControlService.acceptedInvitationSubject$.subscribe((i) => {
                const index = this.meetingInvitations.findIndex(
                    (invitation) => invitation.inviter.roomId === i.inviter.roomId && invitation.invitees.some((invitee) => i.invitees.some((targetInvitee) => invitee.roomId === targetInvitee.roomId))
                );

                if (index > -1) {
                    this.meetingInvitations[index] = i;
                }

                this.router.navigate([`meeting/${i.jitsiRoomId}/${this.user.firstName}_${this.user.lastName}`]);
            })
        );
        this.subscriptions.push(
            this.meetingsControlService.hideMeetingsControlOutOfScreenSubject$.subscribe((hidden) => {
                this.meetingsControlOutOfScreen = hidden;
            })
        );
        this.meetingsControlService.identify();
    }

    ngAfterViewInit() {
        this.webinarStreamVisible = this.router.url.includes("meeting") ? true : false;
        this.setup3QSDNPlayer();
    }

    setup3QSDNPlayer() {
        if (this.webinarStreamVisible) {
            const _js3qi = setInterval(() => {
                if (typeof js3q !== "undefined") {
                    clearInterval(_js3qi);
                    this.js3qVideoPlayer = new js3q({
                        dataid: this.organizationSettings.id3qsdn,
                        container: "webinarPlayerMeetingControl",
                        // muted: this.router.url.includes("meeting") ? false : true, => we now set volume to 15% (see below)
                        autoplay: true
                    });

                    // When user is in meeting, the webcast will play with 15% volume. We try to set it via interval, since there is no init-done callback on player object
                    if (this.router.url.includes("meeting")) {
                        const _volumeInterval = setInterval(() => {
                            try {
                                this.js3qVideoPlayer.volume(0.15);
                            } catch (err) {
                                return;
                            }
                            clearInterval(_volumeInterval);
                        }, 100);
                    }
                }
            }, 10);
        }
    }

    private setStatusForm(state: string) {
        this.statusControl = new FormControl(state);
        this.subscriptions.push(
            this.statusControl.valueChanges.subscribe((val) => {
                this.user.state = val;
                this.meetingsControlService.changeState(val);
            })
        );
    }

    toggleMeetingsControl(): void {
        this.meetingsControlVisible = !this.meetingsControlVisible;
    }

    toggleMeetingsControlOutOfScreen(): void {
        this.meetingsControlOutOfScreen = !this.meetingsControlOutOfScreen;
    }

    sendInvitation(inviteeRoomId: string): void {
        const meetingUsers = this.meetingUsers.filter((user) => user.roomId === inviteeRoomId);

        if (meetingUsers.length > 0) {
            const meetingUser = meetingUsers[0];
            const invitations = this.meetingInvitations.filter((invitation) => invitation.invitees.some((invitee) => invitee.roomId === meetingUser.roomId) || invitation.inviter.roomId === meetingUser.roomId);
            const inMeetingInvitations = this.meetingInvitations.filter((invitation) => invitation.inMeeting && invitation.inviter.roomId === meetingUser.roomId);

            if (invitations.length > 0 || inMeetingInvitations.length > 0) {
                return;
            }

            this.meetingsControlService.inviteMeetingUser(meetingUser);
        }
    }

    acceptInvitation(invitation: IMeetingInvitation) {
        this.meetingsControlService.acceptInvitation(invitation);
    }

    rejectInvitation(invitation: IMeetingInvitation) {
        this.meetingsControlService.rejectInvitation(invitation);
    }

    cancelInvitation(invitation: IMeetingInvitation) {
        this.meetingsControlService.cancelInvitation(invitation);
    }

    outgoingInvitations(includeInMeeting: boolean): Array<IMeetingInvitation> {
        const f = (invitation: IMeetingInvitation): boolean => {
            return includeInMeeting
                ? invitation.inviter.roomId === this.user.roomId && invitation.jitsiRoomId !== undefined && invitation.jitsiRoomId !== ""
                : (invitation.inviter.roomId === this.user.roomId && invitation.jitsiRoomId === undefined) || invitation.jitsiRoomId === "";
        };
        const outgoingInvitations = this.meetingInvitations.filter((i) => f(i));
        return outgoingInvitations;
    }

    incommingInvitations(): Array<IMeetingInvitation> {
        const incommingInvitations = this.meetingInvitations.filter(
            (i) => i.inviter.roomId !== this.user.roomId && i.invitees.some((invitee) => invitee.roomId === this.user.roomId) && !i.alreadyAttendingUserRoomIds.some((rId) => rId === this.user.roomId)
        );

        return incommingInvitations;
    }

    ongoingInivitationFor(user: IMeetingUser): IMeetingInvitation | null {
        const result = this.outgoingInvitations(false).filter((invitation) => invitation.invitees.some((invitee) => invitee.email === user.email) && (invitation.jitsiRoomId === undefined || invitation.jitsiRoomId === ""));
        if (result.length > 0) {
            return result[0];
        }
        return null;
    }

    incomingInivitationFor(user: IMeetingUser): IMeetingInvitation | null {
        const result = this.incommingInvitations().filter((invitation) => invitation.inviter.email === user.email);
        if (result.length > 0) {
            return result[0];
        }
        return null;
    }

    positionClass(): string {
        let additionTopClass = "";
        if (this.router.url.includes("/meeting")) {
            additionTopClass = " mt-100";
        }
        const position = "control-position-" + this.position();
        return (this.meetingsControlOutOfScreen ? position + "-oos" : position) + additionTopClass;
    }

    hiderPositionClass(): string {
        let additionTopClass = "";
        if (this.router.url.includes("/meeting")) {
            additionTopClass = " mt-100";
        }

        const hiderPosition = "hider-" + this.position();
        return (this.meetingsControlOutOfScreen ? hiderPosition + "-collapsed" : hiderPosition) + additionTopClass;
    }

    private position(): string {
        // if (this.router.url.includes("/meeting")) { => GMP-78: now it is always on the right side
        //     return "left";
        // }

        return "right";
    }

    canInvite(user: IMeetingUser): boolean {
        if (this.outgoingInvitations(false).length > 0) {
            return false;
        }

        return !this.meetingInvitations.some((i) => i.inviter.email === user.email || i.alreadyAttendingUserRoomIds.some((roomId) => user.roomId === roomId) || i.invitees.some((invitee) => invitee.roomId === user.roomId));
    }

    canShowWebinarStream(): boolean {
        return this.router.url !== "/lecturehall";
    }

    toggleWebinarStream(): void {
        this.webinarStreamVisible = !this.webinarStreamVisible;
        this.setup3QSDNPlayer();
    }
    logout() {
        const organizationKey = this.oauthStorageAdapter.getCurrentOrganizationKey();
        this.subscriptions.push(
            this.authenticationService.logout().subscribe(
                (result) => {
                    this.meetingsControlService.disconnectSocket();
                    OAuthStorageAdapter.clearStorage();
                    this.router.navigate(["/login", organizationKey]);
                },
                (err) => {
                    this.meetingsControlService.disconnectSocket();
                    OAuthStorageAdapter.clearStorage();
                    this.router.navigate(["/login", organizationKey]);
                }
            )
        );
    }

    chatWithUser(userId: string) {
        CometChatWidget.openOrCloseChat(true);
        CometChatWidget.chatWithUser(userId);
    }
}
