<div class="container">
    <div class="website-element">
        <img src="assets/img/landingpages/womens-health/header.png"/>
    </div>
    <article>
        <br/>
        <h1><span class="orange" style="font-size: 1.85rem;">Womens Health</span></h1>
        <h1>Samstag, den 5. März 2022, 11:00 - 14:00 Uhr</h1>
        <p>
            Sehr geehrte Kolleginnen und Kollegen,
        </p>
        <p>
            Die Behandlung des Ovarialkarzinoms hat durch
die Einführung der PARP-Inhibitoren in den
vergangenen Jahren enorme Fortschritte und Erfolge
erzielt. Insbesondere hat der Stellenwert einer
medikamentösen Erhaltungstherapie zunehmend
an Bedeutung gewonnen.<sup>1</sup>
        </p>
        <p>
            Das Gleiche gilt auch für das Endometriumkarzinom
– hier gab es im letzten Jahr neue spannende
Therapieoptionen. Die molekulare Testung ist hier
eine wichtige Voraussetzung.<sup>2,3</sup> Zu diesen Themen
möchte ich mit Ihnen gemeinsam als routinierte
Kolleginnen und Kollegen einen lebhaften Diskurs
über Ihre Erfahrungen im Umgang mit diesen
Therapien führen. Ausdrücklich ermuntern möchte
ich Sie, gerne eigene Fälle vorzustellen und in die
Diskussion einzubringen.
        </p>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="efn" name="efn" #efnInput="ngModel" [(ngModel)]="this.registration.misc.efn" type="text" class="form-control" required minlength="15" maxlength="15" pattern="^80276(.*)"  />
                        <label>{{ "registration.labels.efn" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="efnInput.invalid && (efnInput.dirty || efnInput.touched)">
                            <p>Ungültige EFN-Nummer, 15 Ziffern</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted" style="margin-left:25px; margin-top:-55px;">Hiermit bestätige ich, dass ich ein in Deutschland, Österreich, Schweiz zugelassener Arzt/Ärztin bin.</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>Sie müssen ein in Deutschland, Österreich, Schweiz zugelassener Arzt/Ärztin sein um teilnehmen zu dürfen</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>Ihre Registrierung war erfolgreich. Sie werden in Kürze eine Bestätigungs-E-Mail erhalten. </p>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr class="orange strong">
                    <td>11:00 - 11:15 Uhr</td>
                    <td>Einleitung und Vorstellung</td>
                </tr>
                <tr class="orange strong">
                    <td style="vertical-align: top;">11:15 –13:00 Uhr</td>
                    <td>
                        Impulse und Podiumsdiskussion zur Therapie mit
                        <ul>
                            <li>PARP-Inhibitoren zur Erhaltungstherapie beim Ovarialkarzinom</li>
                            <li>PD-1-Inhibitoren beim Endometriumkarzinom</li>
                            <ul>
                                <li>Vergangenheit / Gegenwart / Zukunft</li>
                                <li>Erfahrungen</li>
                                <li>Welche Patientin ist für eine PARPi-,
                                bzw. für eine PD-1-Therapie geeignet?</li>
                                <li>Welchen PARP-, bzw. PD-1-Inhibitor
                                setze ich wann und warum ein?</li>
                                <li>Wie gehe ich mit Nebenwirkungen um?</li>
                            </ul>
                        </ul>
                    </td>
                </tr>
                <tr class="orange strong">
                    <td>13:00 –13:45 Uhr</td>
                    <td>Sie sind gefragt! lhre Fälle in der Diskussion</td>
                </tr>
                <tr class="orange strong">
                    <td>13:45 –14:00 Uhr</td>
                    <td>Zusammenfassung Dr. Overkamp / alle</td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/womens-health/Lets-talk-about-Einladung.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="/assets/img/landingpages/womens-health/Womens-Health.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>iPad-Nutzer:</h2>
            <p>Bitte stellen Sie sicher, dass auf Ihrem iPad alle aktuellen Updates installiert sind.</p>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <ol style="padding-left:20px;">
            <li style="font-size: 1.15rem;">Leitlinienprogramm Onkologie (Deutsche Krebsgesellschaft, Deutsche Krebshilfe, AWMF): S3-Leitlinie Diagnostik, Therapie und Nachsorge maligner
                Ovarialtumoren, Langversion 5.0, 2021, AWMF-Registernummer: 032/035OL, https://www.leitlinienprogramm-onkologie.de/leitlinien/ovarialkarzinom/,
                [abgerufen am: 04.02.2022].</li>
            <li style="font-size: 1.15rem;">Jemperli Fachinformation, Stand: Juli 2021.</li>
            <li style="font-size: 1.15rem;">Lenvima Fachinformation, Stand: November 2021.</li>
        </ol>

        <p class="footnote">
            
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <span style="font-size: 0.85rem;">PM-DE-DST-LBND-210008 (02/2022)</span>
        </p> 

    </article>
</div>
