import { Component, AfterViewInit, NgZone, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { LosTrackingService } from "src/app/services/lostracking.service";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";
import { TawkService } from "../tawk/tawk.service";

declare const embedpano: any;
declare const clicktracker: any;
declare const gotoUrl: any; // <= defined in index.html

@Component({
    selector: "app-reception",
    templateUrl: "./reception.component.html",
    styleUrls: ["./reception.component.scss"]
})
export class ReceptionComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    private organizationSettings: IOrganizationSettings;

    constructor(private _ngZone: NgZone, private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private tawkService: TawkService, private losTrackingService: LosTrackingService) {
        super();

        window["receptionComponentRef"] = { component: this, zone: _ngZone };

        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
        if (this.organizationSettings.clickTracking) {
            clicktracker.setup(this.organizationSettings?.clickTracking?.token, this.organizationSettings?.clickTracking?.endpoint, this.organizationSettings?.clickTracking?.tenantId);
            clicktracker.click("Pagehit - Reception");
        }
    }

    // Usage in KRPano => onclick="js(gotoLectureHall())"
    gotoLectureHall() {
        if (this.organizationSettings.key === "immuntherapie-endometriumkarzinom") {
            gotoUrl(
                "https://teams.microsoft.com/l/meetup-join/19%3ameeting_ODNmNmUxNzUtOWJhNS00MmE1LTlmYzYtZDEwMzE5ZTQxZDg2%40thread.v2/0?context=%7b%22Tid%22%3a%22e676a9d3-854a-46b1-9175-7e50a01933da%22%2c%22Oid%22%3a%22e6865e9d-8833-46ea-974c-891f87fd9478%22%7d"
            );
            return;
        }
        this.router.navigate(["/lecturehall"]);
    }

    // Usage in KRPano => onclick="js(toggletawkto())"
    toggleTawkTo() {
        this.tawkService.ExpandChatWindow(true);
    }

    // Usage in KRPano => onclick="jscall(clickSpot('BoC - SGO 2020'));"
    clickSpot(elementName) {
        clicktracker.click(elementName);
    }

    ngAfterViewInit() {
        embedpano({
            swf: this.organizationSettings.panoSwf,
            xml: this.organizationSettings.panoXml,
            target: "reception-pano",
            html5: "auto",
            mobilescale: 1.0,
            passQueryParameters: true
        });
        this.tawkService.SetChatVisibility(true);

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "reception", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "reception", undefined);
                }
            )
        );
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        this.tawkService.SetChatVisibility(false);
        this.losTrackingService.closeSocketConnection();
    }
}
