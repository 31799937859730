<div id="webinarPlayerContainer">
    <div id="webinarPlayer"></div>
</div>
<div id="vevox-iframe">
    <iframe src="https://vevox.app/#/m/161207654"></iframe>
</div>
<img id="gsk-logo" src="/assets/img/gsk_logo.png"/>

<footer class="page-footer">
    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">
        Die Inhalte richten sich an medizinische Fachkreise in Deutschland | © 2022 Copyright:
        <a href="https://de.gsk.com/de-de/"> https://de.gsk.com/de-de/</a>
        <br />
        <div class="footer-links">
            <!-- <a href="#/imprint">{{ "footer.links.imprint" | translate }}</a> |
            <a href="#/data_legal">{{ "footer.links.privacy" | translate }}</a> |
            {{ 'footer.support' | translate }} -->

            <a href="https://de.gsk.com/de-de/uber-uns/">Über GSK</a> | 
            <a href="https://de.gsk.com/de-de/impressum/">Impressum</a> | 
            <a href="https://de.gsk.com/de-de/nutzungsbedingungen/">Nutzungsbedingungen</a> | 
            <a href="https://privacy.gsk.com/de-de/privacy-notice/">Datenschutzbestimmungen</a> | 
            <a href="https://gskpro.com/de-de/kontakt/">Kontakt/Nebenwirkung melden</a> | 
            {{ 'footer.support' | translate }}
        </div>
    </div>
    <!-- Copyright -->
</footer>