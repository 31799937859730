<div *ngIf="organizationSettings.showStatusBox" [ngClass]="this.hiderPositionClass()" class="col-12 d-flex justify-content-center clickable" (click)="this.toggleMeetingsControlOutOfScreen()">
    <mat-icon *ngIf="!this.meetingsControlOutOfScreen">expand_more</mat-icon>
    <mat-icon *ngIf="this.meetingsControlOutOfScreen">expand_less</mat-icon>
</div>
<div *ngIf="organizationSettings.showStatusBox" class="meetings-control row" [ngClass]="this.positionClass()">
    <div class="col-12 d-flex pt-2 align-items-center">
        <mat-icon class="me-icon">person</mat-icon>
        <div class="me-text">{{ user.firstName + " " + user.lastName }}</div>
    </div>
    <div class="col-12 d-flex flex-row justify-content-between align-items-baseline">
        <mat-form-field appearance="standard">
            <mat-label>{{ 'meetings.text.status' | translate }}</mat-label>
            <mat-select [formControl]="statusControl">
                <mat-select-trigger>
                    <mat-icon class="small-icon" [ngClass]="(user.state == 'occupied') ? 'offline-icon' : 'online-icon'">brightness_1</mat-icon>
                    {{ 'meetings.labels.' + user.state | translate }}
                </mat-select-trigger>
                <mat-option value="available">
                    <mat-icon class="small-icon online-icon">brightness_1</mat-icon>
                    {{ 'meetings.labels.available' | translate }}
                </mat-option>
                <mat-option value="occupied">
                    <mat-icon class="small-icon offline-icon">brightness_1</mat-icon>
                    {{ 'meetings.labels.occupied' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button (click)="logout()">{{ 'meetings.buttons.logout' | translate }}</button>
    </div>
    <mat-divider class="w-100"></mat-divider>

    <div class="col-12 list-body">
        <div class="row">
            <div *ngIf="this.canShowWebinarStream() && !this.webinarStreamVisible" class="col-12 justify-content-center text-center webinar-stream-label">
                <mat-icon>remove_red_eye</mat-icon>
                <span style="padding-left: 9px;">{{ 'meetings.labels.webinar_stream' | translate}}</span>
            </div>
            <div id="webinarStreamContainer" *ngIf="this.canShowWebinarStream() && this.webinarStreamVisible" class="col-12 d-flex flex-column webinar-stream">
                <div id="webinarPlayerMeetingControl"></div>
            </div>
            <div *ngIf="this.canShowWebinarStream()" class="col-12 my-1 d-flex justify-content-center clickable" (click)="this.toggleWebinarStream()">
                <mat-icon *ngIf="!this.webinarStreamVisible">expand_more</mat-icon>
                <mat-icon *ngIf="this.webinarStreamVisible">expand_less</mat-icon>
            </div>
            <div *ngIf="this.incommingInvitations().length > 0" class="col-12 d-flex flex-column">
                <div class="small-text pt-1">{{'meetings.labels.invitations' | translate}}</div>
                <div *ngFor="let invitation of this.incommingInvitations()" class="d-flex flex-column">
                    <div class="d-flex flex-row justify-content-between my-2 align-items-center">
                        <div>{{ invitation.inviter.firstName + " " + invitation.inviter.lastName }}</div>
                        <div class="d-flex flex-row">
                            <button mat-raised-button class="positive-btn mr-2" (click)="this.acceptInvitation(invitation)">{{ 'meetings.buttons.accept' | translate }}</button>
                            <button mat-raised-button class="negative-btn" (click)="this.rejectInvitation(invitation)">{{ 'meetings.buttons.decline' | translate }}</button>
                        </div>            
                    </div>            
                    <mat-divider class="ml-4"></mat-divider>
                </div>
            </div>
            <div class="col-12 text-center mt-1" *ngIf="!this.meetingsControlVisible">{{ "meetings.buttons.meetings_control" | translate }} ({{ this.meetingUsers.length }})</div>    
            <mat-list class="col-12 px-0 online-users" *ngIf="this.meetingsControlVisible">        
                <div class="px-3 small-text">{{'meetings.labels.persons' | translate}}</div>
                <mat-list-item *ngFor="let user of this.meetingUsers" (mouseover)="userFocussed = true" (mouseout)="userFocussed = false">
                    <mat-icon mat-list-icon class="small-icon" [ngClass]="(user.state == 'occupied')?'offline-icon':'online-icon'">brightness_1</mat-icon>
                    <div mat-line>{{ user.firstName + " " + user.lastName }}</div>
                    <div mat-line class="small-text" *ngIf="ongoingInivitationFor(user)">
                        <mat-icon class="small-icon online-icon animate__animated animate__heartBeat animate__infinite">call</mat-icon>
                        {{ 'meetings.text.waiting_for_response' | translate }}                
                    </div>

                    <button class="positive-btn mr-1" mat-raised-button *ngIf="showChatIcon" (click)="chatWithUser(user.userId)">{{ 'meetings.buttons.chat' | translate }}</button>

                    <button class="negative-btn" *ngIf="ongoingInivitationFor(user); let invitation" mat-raised-button (click)="this.cancelInvitation(invitation)">{{ 'meetings.buttons.cancel_invitation' | translate }}</button>
                    <button class="positive-btn" mat-raised-button *ngIf="canInvite(user)" (click)="this.sendInvitation(user.roomId)">{{ 'meetings.buttons.start_meeting' | translate }}</button>
                    <div *ngIf="incomingInivitationFor(user)" class="small-text" mat-line>{{ 'meetings.text.open_invitation' | translate }}</div>
                    <!-- <mat-icon mat-list-icon class="icon clickable" (click)="chatWithUser(user.userId)" *ngIf="showChatIcon">chat</mat-icon> -->
                    <mat-divider class="ml-4"></mat-divider>
                </mat-list-item>
            </mat-list>
            <div class="col-12 my-1 d-flex justify-content-center clickable" (click)="toggleMeetingsControl()">
                <mat-icon *ngIf="!this.meetingsControlVisible">expand_more</mat-icon>
                <mat-icon *ngIf="this.meetingsControlVisible">expand_less</mat-icon>
            </div>
        </div>        
    </div>        
</div>
