import { BrowserModule } from "@angular/platform-browser";
import { NgModule, Injector, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReceptionComponent } from "./components/reception/reception.component";
import { LectureHallComponent } from "./components/lecturehall/lecturehall.component";
import { WaitingRoomComponent } from "./components/waitingroom/waitingroom.component";
import { LoginComponent } from "./components/login/login.component";
import { FastLoginComponent } from "./components/fastlogin/fastlogin.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthenticationGuard } from "./AuthenticationGuard";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { TranslateModule, TranslateService, TranslateLoader } from "@ngx-translate/core";
import { translateLoaderService } from "./global/translate/translateloader.service";
import { LOCATION_INITIALIZED } from "@angular/common";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { AlertComponent } from "./components/alert/alert.component";
import { HttpErrorInterceptor } from "./http-error.interceptor";
import { SingleLayoutComponent } from "./components/single-layout/single-layout.component";
import { FullLayoutComponent } from "./components/full-layout/full-layout.component";
import { AboutComponent } from "./components/about/about.component";
import { DataLegalComponent } from "./components/data-legal/data-legal.component";
import { MeetingsControlComponent } from "./components/meetings-control/meetings-control.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule, MatAnchor } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MeetingComponent } from "./components/meeting/meeting.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "../environments/environment";
import { RouteReuseStrategy } from "@angular/router";
import { CustomRouteReuseStrategy } from "./global/CustomRouteReuseStrategy";
import { TawkService } from "./components/tawk/tawk.service";
import { LosTrackingService } from "./services/lostracking.service";
import { PostAscoRegistrationComponent } from "./components/landingpages/postasco/registration.component";
import { EndoCaRegistrationComponent } from "./components/landingpages/endo-ca/registration.component";
import { WerImpftMichRegistrationComponent } from "./components/landingpages/wer-impft-mich/registration.component";
import { Boc2021RegistrationComponent } from "./components/landingpages/boc-2021/registration.component";
import { StudienInformationstagCaRegistrationComponent } from "./components/landingpages/studien-informationstag/registration.component";
import { BehandlungOvarRegistrationComponent } from "./components/landingpages/behandlung-ovar/registration.component";
import { WomensHealthRegistrationComponent } from "./components/landingpages/womens-health2/registration.component";
import { WomensHealthDocCheckComponent } from "./components/landingpages/womens-health/registration.component";
import { OnkoCampusHcpDocCheckComponent } from "./components/landingpages/oc-hcp-doccheck/registration.component";
import { OnkoCampusHcpRegistrationComponent } from "./components/landingpages/onko-campus-hcp/registration.component";
import { OnkoCampusMfaRegistrationComponent } from "./components/landingpages/onko-campus-mfa/registration.component";
import { GSKTagungComponent } from "./components/landingpages/gsk-tagung/gsktagung.component";
import { OnkoCampus2023RegistrationComponent } from "./components/landingpages/onko-campus-2023/registration.component";
import { WomensCancerRegistrationComponent } from "./components/landingpages/womens-cancer/registration.component";
import { CD226RegistrationComponent } from "./components/landingpages/cd226/registration.component";

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                translate.addLangs(["en"]);

                const langToSet = "en";
                translate.setDefaultLang("en");
                translate.use(langToSet).subscribe(
                    () => {
                        // language initialized
                    },
                    (err) => {
                        console.error(`Problem with '${langToSet}' language initialization.'`);
                    },
                    () => {
                        resolve(null);
                    }
                );
            });
        });
}

export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }];
const socketIoConfig: SocketIoConfig = {
    url: environment.socketIoURI,
    options: { transports: ["websocket"] }
};
@NgModule({
    declarations: [
        AppComponent,
        ReceptionComponent,
        LoginComponent,
        FastLoginComponent,
        RegistrationComponent,
        ResetPasswordComponent,
        AlertComponent,
        AboutComponent,
        DataLegalComponent,
        SingleLayoutComponent,
        FullLayoutComponent,
        MeetingsControlComponent,
        MeetingComponent,
        LectureHallComponent,
        WaitingRoomComponent,
        PostAscoRegistrationComponent,
        EndoCaRegistrationComponent,
        WerImpftMichRegistrationComponent,
        Boc2021RegistrationComponent,
        StudienInformationstagCaRegistrationComponent,
        BehandlungOvarRegistrationComponent,
        WomensHealthRegistrationComponent,
        WomensHealthDocCheckComponent,
        OnkoCampusHcpDocCheckComponent,
        OnkoCampusHcpRegistrationComponent,
        OnkoCampusMfaRegistrationComponent,
        GSKTagungComponent,
        OnkoCampus2023RegistrationComponent,
        WomensCancerRegistrationComponent,
        CD226RegistrationComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        BrowserAnimationsModule,
        MatIconModule,
        MatListModule,
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        SocketIoModule.forRoot(socketIoConfig)
    ],
    providers: [
        AuthenticationGuard,
        LosTrackingService,
        OAuthStorageAdapter,
        httpInterceptorProviders,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true
        },
        {
            provide: TranslateLoader,
            useFactory: translateLoaderService,
            deps: [HttpClient]
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        TawkService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
