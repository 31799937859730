<div class="app-login">
    <div class="company-logo">
        <img src="assets/logo.png" />
    </div>
    <form name="form" #f="ngForm" *ngIf="enableLogin">
        <div class="cross-validation">

            <div class="form-group form-group--float form-group--centered">
                <input #email="ngModel" [(ngModel)]="loginEmail" name="email" type="text" class="form-control" email required />
                <label>{{ "login.labels.email" | translate | titlecase }}</label>
                <div class="validation-error alert alert-danger" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <p>{{ 'login.validation.email' | translate }}</p>
                </div>
            </div>

            <div class="form-group form-group--float form-group--centered">
                <input #password="ngModel" [(ngModel)]="loginPassword" name="password" type="password" class="form-control" required />
                <label>{{ "login.labels.password" | translate | titlecase }}</label>
                <div class="validation-error alert alert-danger" *ngIf="password.errors?.required && (password.dirty || password.touched)">
                    <p>{{ 'login.validation.password' | translate }}</p>
                </div>
            </div>

            <button (click)="login()" type="submit" class="btn btn-primary btn-block" [disabled]="f.invalid" *ngIf="enableLogin">
                {{ "login.buttons.login" | translate }}
            </button>
        </div>
    </form>
    <p class="text-center" *ngIf="enableLogin">
        <a (click)="gotoRegistration()" style="color: #007bff; cursor: pointer;">{{ "login.links.register_account" | translate }}</a>
    </p>
    <p class="text-center" *ngIf="enableLogin">
        <a [routerLink]="'/reset_password/' + organizationKey">{{ "login.links.forgot_password" | translate }}</a>
    </p>

    <p class="text-center" *ngIf="!enableLogin">
        {{ i18nBlockedLoginTextKey | translate : { logindate: loginEnabledDate } }}
    </p>
</div>
