<div id="contentContainer">
    <div id="logoContainer">
        <a href="https://de.gsk.com/de-de/"><img src="assets/img/gsk_logo.png"></a>
    </div>
    <div id="application">
        <h1>Impressum</h1>
        <hr />

        <table width="95%" border="0" cellspacing="1" cellpadding="1">
            <tbody>
                <tr>
                    <td align="left" valign="top">
                        <div>
                            <h2>GlaxoSmithKline GmbH &amp; Co. KG</h2><br />
                            Prinzregentenplatz 9 <br />
                            D-81675 München <br />
                            <br />
                            Vertreten durch die Allen Pharmazeutika Gesellschaft m.b.H, Wien <br />
                            <br />
                            <strong>Geschäftsführung: </strong><br />
                            Victoria Williams (Vors.)<br />
                            <br />
                            Tel.: + 49 (0) 89 360 44 0 <br />
                            Fax: + 49 (0) 89 360 44 8000 <br />
                            <br />
                            <strong>Medizinisches Info & Service-Center: </strong><br />
                            Tel.: 0800 1 22 33 55 (08.00 bis 18.00 Uhr) <br />
                            <br />
                            E-Mail: <a target="_blank" title="produkt.info@gsk.com" href="mailto:produkt.info@gsk.com"> produkt.info[at]gsk.com </a><br />
                            Internet: <a href="http://de.gsk.com" target="_blank">http://de.gsk.com</a><br />
                            <br />
                            <strong>Registergericht:</strong> Amtsgericht München <br />
                            <br />
                            <strong>Handelsregister:</strong> HRA 78754<br />
                            <br />
                            <strong>Umsatzsteuer-Identifikationsnummer:</strong> DE 813 233 122 <br />
                            <br />
                            <strong>Zuständige Aufsichtsbehörde</strong><br />
                            Regierung von Oberbayern<br />
                            Maximilianstraße 39<br />
                            D-80538 München
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
        <h2>Richtlinien zu unseren Social Media Angeboten mit Bezug zu Indikationen</h2>

        <p>GSK betreibt in Deutschland mehrere Social Media Angebote z.B. bei Facebook, Twitter oder Instagram die es ermöglichen, Aufklärungsarbeit zu verschiedenen Indikationen zu leisten.</p>
        <p>Wenn Sie uns Posts zusenden oder unsere Posts kommentieren, bitten wir Sie, unsere Richtlinien zu unseren Aktivitäten auf Social Media Angeboten zu beachten.</p>
        <p><strong>Unsere Verhaltensregeln auf Social Media Angeboten von GSK</strong></p>
        <p>Wir verpflichten uns nicht dazu, auf Verlinken zu reagieren und/oder zu antworten. Des Weiteren sind wir nicht für veränderte Inhalte von z.B. Re-Tweets oder Verlinkungen durch andere Nutzer verantwortlich.</p>
        <p>
            Bitte berichten Sie alle unerwünschten Ereignisse im Zusammenhang mit Produkten von GSK, durch Nutzung <a href="https://www.gsk-kontakt.de/contact_new/index.html?contentarea=pub&amp;referer=imprint">dieses Kontaktformulars</a>. Als
            Hersteller von Arzneimitteln sind wir gesetzlich verpflichtet, jede Nennung eines unerwünschten Ereignisses im Zusammenhang mit unseren Produkten zu recherchieren und ggf. an die zuständige Bundesoberbehörde weiterzuleiten. Um diese
            Maßgabe umzusetzen, werden wir ggf. mit Ihnen mittels hinterlegter E-Mail Adresse oder Anmeldenamen in Verbindung treten.
        </p>
        <p>
            GSK ist nicht verantwortlich für die Geschäftsbedingungen und Datenschutzrichtlinien der durch Links und Verweise in unseren Posts aufgegriffenen Websites. GSK behält sich alle Rechte in Bezug auf die Social Media Angebote vor,
            einschließlich, aber nicht nur beschränkt auf:
        </p>
        <ul>
            <li>das Hinzufügen, Entfernen oder Ändern von Inhalten, die unter diesem Account gepostet wurden</li>
            <li>andere Nutzer zu blockieren</li>
            <li>das Angebot jederzeit einzustellen</li>
        </ul>
        <p>
            Medizinische und gesundheitliche Themen können diskutiert werden, aber sollten nicht als medizinische Beratung ausgelegt werden. Die veröffentlichten Inhalte stellen keine Alternative zur Beratung durch einen Arzt oder Apotheker
            dar. Sofern Sie Rat zu spezifischen Gesundheitsproblemen benötigen, wenden Sie sich bitte ausschließlich an Ihren Arzt oder Apotheker.
        </p>

        <hr />
    </div>

</div>