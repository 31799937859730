import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import { IOrganizationSettings, OAuthStorageAdapter } from "../../OAuthStorageAdapter";
import { BaseComponent } from "../BaseComponent";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { LosTrackingService } from "src/app/services/lostracking.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { IBreakoutGroup } from "src/app/models/IBreakoutGroup";
import { TawkService } from "../tawk/tawk.service";

declare var js3q;

@Component({
    selector: "app-lecturehall",
    templateUrl: "./lecturehall.component.html",
    styleUrls: ["./lecturehall.component.scss"]
})
export class LectureHallComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    public js3qVideoPlayer: any = null;
    public chatVisible = true;
    public meetpsVisible = true; // window.innerWidth >= 1200 === true;
    public meetpsUrl;
    public breakoutGroup: IBreakoutGroup | null = null;
    public showBreakoutGroup = false;

    public organizationSettings: IOrganizationSettings;
    public disable3qsdnChat: boolean = false;

    public hasMultipleVideoIds: boolean = false;
    public activeVideo = 1;

    constructor(
        private oauthStorageAdapter: OAuthStorageAdapter,
        private router: Router,
        private sanitizer: DomSanitizer,
        private losTrackingService: LosTrackingService,
        private authenticationService: AuthenticationService,
        private tawkService: TawkService
    ) {
        super();

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        this.organizationSettings = this.oauthStorageAdapter.getOrganizationSettings();
        this.hasMultipleVideoIds = !!this.organizationSettings.id3qsdn && !!this.organizationSettings.id3qsdn2dn && !!this.organizationSettings.id3qsdnSwitch1 && !!this.organizationSettings.id3qsdnSwitch2;

        this.meetpsUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.organizationSettings.meetps);

        const webinarStart = moment(this.organizationSettings.webinarStart, environment.webinarTimeformat);
        const now = moment();

        // GMP-22: go to waiting room if webinar did not start yet
        if (this.organizationSettings.waitingRoomFeature && now.isBefore(webinarStart.subtract(30, "seconds"))) {
            this.router.navigate(["/waitingroom"]);
            return;
        }

        // load possible breakoutgroup data
        this.subscriptions.push(
            this.authenticationService.breakoutGroup().subscribe((result) => {
                this.breakoutGroup = result;
            })
        );

        // manage breakout group button visibility
        if (this.organizationSettings?.hideBreakoutButtonUntil) {
            const hideBreakoutButtonUntil = moment(this.organizationSettings?.hideBreakoutButtonUntil, environment.hideBreakoutButtonUntilTimeformat);

            if (now.isAfter(hideBreakoutButtonUntil)) {
                this.showBreakoutGroup = true;
            } else {
                const hideBreakoutButtonInterval = setInterval(() => {
                    if (moment().isAfter(hideBreakoutButtonUntil)) {
                        this.showBreakoutGroup = true;
                        clearInterval(hideBreakoutButtonInterval);
                    }
                }, 10000);
            }
        } else {
            this.showBreakoutGroup = true;
        }

        // optional disable the 3qsdn chat
        this.disable3qsdnChat = this.organizationSettings?.disable3qsdnChat ?? false;
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.setup3qsdnChat();

        const auth = this.oauthStorageAdapter.getCurrentOAuthResult();
        const jwt = auth.jwt ?? "";

        this.subscriptions.push(
            this.losTrackingService.cloudflareLookup().subscribe(
                (result) => {
                    const data: any = result
                        .trim()
                        .split("\n")
                        .reduce((obj, pair: any) => {
                            pair = pair.split("=");
                            return (obj[pair[0]] = pair[1]), obj;
                        }, {});

                    this.losTrackingService.setupSocketConnection(jwt, "lecturehall", data?.loc);
                },
                (err) => {
                    this.losTrackingService.setupSocketConnection(jwt, "lecturehall", undefined);
                }
            )
        );

        this.tawkService.SetChatVisibility(true);
    }

    private setup3qsdnChat(): void {
        if (this.disable3qsdnChat) {
            const _js3qi = setInterval(() => {
                if (typeof js3q !== "undefined") {
                    clearInterval(_js3qi);
                    this.js3qVideoPlayer = new js3q({
                        dataid: this.organizationSettings.id3qsdn,
                        container: "webinarPlayer"
                    });
                }
            }, 10);
        } else {
            const _js3qi = setInterval(() => {
                if (typeof js3q !== "undefined") {
                    clearInterval(_js3qi);
                    const chatContainer = document.querySelector("#chatContainer");
                    this.js3qVideoPlayer = new js3q({
                        dataid: this.organizationSettings.id3qsdn,
                        container: "webinarPlayer",
                        comments: chatContainer
                    });
                }
            }, 10);

            const _js3qChati = setInterval(() => {
                try {
                    (document.querySelector("js3q-sidebar-footer input") as any).value = "Anonym";
                    clearInterval(_js3qChati);
                } catch (e) {}
            }, 500);
        }
    }

    enterFullscreen(): void {
        this.js3qVideoPlayer.fullscreen();
    }

    switchToVideo1(): void {
        this.js3qVideoPlayer.destroy();
        this.js3qVideoPlayer = new js3q({
            dataid: this.organizationSettings.id3qsdn,
            container: "webinarPlayer"
        });
        this.activeVideo = 1;
    }

    switchToVideo2(): void {
        this.js3qVideoPlayer.destroy();
        this.js3qVideoPlayer = new js3q({
            dataid: this.organizationSettings.id3qsdn2dn,
            container: "webinarPlayer"
        });
        this.activeVideo = 2;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.losTrackingService.closeSocketConnection();
        this.tawkService.SetChatVisibility(false);
    }

    toReception() {
        this.router.navigate(["/reception"]);
    }

    toggleMeetps() {
        this.meetpsVisible = !this.meetpsVisible;
    }

    toggleChat() {
        this.chatVisible = !this.chatVisible;
    }

    gotoBreakoutGroup() {
        if (this.breakoutGroup?.link) {
            const checkLink = this.breakoutGroup.link.toLowerCase().trim();
            let link = this.breakoutGroup.link;

            if (checkLink.indexOf("http") !== 0) {
                link = "http://" + link;
            }

            window.open(link, "_blank");
        }
    }

    openFeedback() {
        window.open("https://health-ecomm.com/GSK/index2.html", "_blank").focus();
    }
}
