import { Component, OnDestroy, OnInit } from "@angular/core";
import * as moment from "moment";
import { IRegistration } from "../../../models/IRegistration";
import { IRegistrationConfirmation } from "../../../models/IRegistrationConfirmation";
import { environment } from "../../../../environments/environment";
import { RegistrationService } from "../../../services/registration.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseComponent } from "../../BaseComponent";

declare const clicktracker: any;

@Component({
    selector: "app-behandlung-ovar-registration",
    templateUrl: "./registration.component.html",
    styleUrls: ["./registration.component.scss"]
})
export class BehandlungOvarRegistrationComponent extends BaseComponent implements OnInit, OnDestroy {
    public organizationKey = "behandlung-ovar";
    public showLoginLink = true;

    public registration: IRegistration = {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        passwordConfirm: "",
        agbAccepted: false,
        language: environment.defaultLanguage,
        organizationKey: "",
        misc: {
            institution: ""
        }
    };

    public showRegistrationFormView: boolean = true;
    public showRegistrationSuccessfulView: boolean = false;

    constructor(private registrationService: RegistrationService, protected route: ActivatedRoute, protected router: Router) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params) => {
                const token = params.get("token");
                if (token) {
                    this.confirmRegistration(token);
                }
            })
        );

        this.registration.organizationKey = this.organizationKey;

        const organizationSettings: any = environment.organizations.find((org) => org.key === this.organizationKey);
        if (organizationSettings?.clickTracking) {
            clicktracker.setup(organizationSettings?.clickTracking?.token, organizationSettings?.clickTracking?.endpoint, organizationSettings?.clickTracking?.tenantId);
            clicktracker.click("landingpage_hit");
        }

        if (organizationSettings?.blockLoginUntil) {
            const blockLoginUntil = moment(organizationSettings.blockLoginUntil, environment.blockLoginUntilTimeformat);
            const now = moment();

            if (now.isBefore(blockLoginUntil)) {
                this.showLoginLink = false;
            }
        }
    }

    register(): void {
        this.subscriptions.push(
            this.registrationService.register(this.registration).subscribe((result: any) => {
                this.showRegistrationFormView = false;
                this.showRegistrationSuccessfulView = true;
            })
        );
    }

    confirmRegistration(token: string): void {
        const registrationConfirmation: IRegistrationConfirmation = {
            token: token,
            language: "en"
        };
        this.subscriptions.push(
            this.registrationService.confirmRegistration(registrationConfirmation).subscribe((result) => {
                this.router.navigate(["/login", this.organizationKey]);
            })
        );
    }

    gotoLogin(): void {
        this.router.navigate(["/login", this.organizationKey]);
    }
}
