<div class="container">
    <div id="logoContainer">
        <a href="https://de.gsk.com/de-de/"><img src="assets/img/gsk_logo.png"></a>
    </div>
    <article>
        <br/>
        <h1><span class="orange" style="font-size: 1.85rem;">GYN-ONKO-CAMPUS</span></h1>
        <h1>08. Mai 2024, 18:00 – 19:20 Uhr</h1>
        <p>
            Virtuelle Veranstaltung
        </p>

        <div class="website-element">
            <img src="assets/img/landingpages/onko-campus-hcp/header.png"/>
        </div>
    </article>
</div>
<div class="container">
    <iframe frameborder="0" scrolling="no" width="467" height="231" name="dc_login_iframe" id="dc_login_iframe" src="https://login.doccheck.com/code/de/2000000018774/login_xl/" ><a href="https://login.doccheck.com/code/de/2000000018774/login_xl/" target="_blank">LOGIN</a></iframe>
</div>
