import { Component, OnInit } from "@angular/core";
import { AlertService } from "../../services/alert.service";
import { BaseComponent } from "../BaseComponent";
import { AlertMessage } from "../../models/AlertMessage";

@Component({
    selector: "app-alert",
    templateUrl: "./alert.component.html",
    styleUrls: ["./alert.component.scss"]
})
export class AlertComponent extends BaseComponent implements OnInit {
    public cssClass: string = "";
    public alertMessage = {
        title: "",
        description: "",
        type: ""
    };

    constructor(private alertService: AlertService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.alertService.getAlert().subscribe((message: AlertMessage) => {
                this.cssClass = message?.type === "error" ? "alert-danger" : "alert-primary";
                this.alertMessage = message;
            })
        );
    }
}
