// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: "test",
    version: require("../../package.json").version,
    httpRequestTimeoutSeconds: 120,
    utcDateFormat: "yyyy-MM-dd HH:mm:ss",
    baseAPI: "https://api.gsk.infillqa.com/api/v1/",
    oauthBaseAPI: "https://api.gsk.infillqa.com/api/v1/auth/",
    resetPasswordAPI: "https://api.gsk.infillqa.com/api/v1/resetpassword/",
    registrationsAPI: "https://api.gsk.infillqa.com/api/v1/registration/",
    breakoutgroupAPI: "https://api.gsk.infillqa.com/api/v1/public/breakoutgroups",
    socketIoURI: "wss://api.gsk.infillqa.com",
    defaultLanguage: "en",
    webinarTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    blockLoginUntilTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    hideBreakoutButtonUntilTimeformat: "YYYY-MM-DD HH:mm:ss ZZ",
    logErrorInConsole: true,
    organizations: [
        {
            key: "gsk",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-10-19 15:19:00 +0200",
            waitingRoomFeature: true,
            panoSwf: "assets/panos/lmu/assets/js/krpano.swf",
            panoXml: "assets/panos/lmu/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: true,
            tawkId: "5fa2b8ade019ee7748f0cf5c",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            cometchat: {
                appID: "31423eff6d2e3b3",
                appRegion: "eu",
                authKey: "3ed00996bc8f1073259b37d2e7a3da8020f4cf5a",
                widgetID: "fad93c86-6ed7-4c3c-b8c1-a0402ef197da",
                docked: "true",
                alignment: "right",
                roundedCorners: "true",
                height: "4px",
                width: "4px"
            },
            meetps: "https://meet.ps/gsk",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "gsk"
            }
        },
        {
            key: "lupus1",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-11-27 15:00:00 +0100",
            waitingRoomFeature: true,
            panoSwf: "assets/panos/lupus/assets/js/krpano.swf",
            panoXml: "assets/panos/lupus/gsk_lupustag_mfa.xml",
            waitingRoomAssets: "/assets/img/lupus/waitingroom/",
            lecturehallBg: "/assets/img/lupus/lecturehall_bg.jpg",
            meetingBg: "/assets/img/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: false
            }
        },
        {
            key: "lupus2",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-11-28 09:00:00 +0100",
            waitingRoomFeature: true,
            panoSwf: "assets/panos/lupus/assets/js/krpano.swf",
            panoXml: "assets/panos/lupus/gsk_lupustag_aerzte.xml",
            waitingRoomAssets: "/assets/img/lupus/waitingroom/",
            lecturehallBg: "/assets/img/lupus/lecturehall_bg.jpg",
            meetingBg: "/assets/img/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15175520b4b7986a0ccdc",
            postLoginDisclaimer: {
                show: false
            }
        },
        {
            key: "nova",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-09-16 15:00:00 +0200",
            waitingRoomFeature: true,
            panoSwf: "assets/panos/nova/assets/js/krpano.swf",
            panoXml: "assets/panos/nova/zejula.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: true,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.nova.title",
                i18nContentKey: "disclaimer.nova.content",
                i18nAgreeButtonKey: "disclaimer.nova.button.agree"
            },
            cometchat: {
                appID: "31423eff6d2e3b3",
                appRegion: "eu",
                authKey: "3ed00996bc8f1073259b37d2e7a3da8020f4cf5a",
                widgetID: "fad93c86-6ed7-4c3c-b8c1-a0402ef197da",
                docked: "true",
                alignment: "right",
                roundedCorners: "true",
                height: "4px",
                width: "4px"
            },
            meetps: "https://meet.ps/gsk",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "nova"
            }
        },
        {
            key: "post_asco",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2020-10-19 15:19:00 +0200",
            waitingRoomFeature: true,
            panoSwf: "assets/panos/lmu/assets/js/krpano.swf",
            panoXml: "assets/panos/lmu/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: true,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            cometchat: {
                appID: "31423eff6d2e3b3",
                appRegion: "eu",
                authKey: "3ed00996bc8f1073259b37d2e7a3da8020f4cf5a",
                widgetID: "fad93c86-6ed7-4c3c-b8c1-a0402ef197da",
                docked: "true",
                alignment: "right",
                roundedCorners: "true",
                height: "4px",
                width: "4px"
            },
            meetps: "https://app.meet.ps/attendee/post-asco",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "post_asco"
            },
            blockLoginUntil: "2021-06-01 01:00:00 +0200",
            loginDateTextFormat: "DD.MM.YYYY", // <= text shown on login mask
            hideBreakoutButtonUntil: "2021-06-23 11:35:00 +0200"
        },
        {
            key: "endo-ca",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2021-09-08 18:00:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/endo-ca/assets/js/krpano.swf",
            panoXml: "assets/panos/endo-ca/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://app.meet.ps/endo-ca",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "endo-ca"
            },
            blockLoginUntil: "2020-09-08 18:00:00 +0200",
            loginDateTextFormat: "DD.MM.YYYY"
        },
        {
            key: "wer-impft-mich",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2020-09-16 15:00:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/wer-impft-mich/assets/js/krpano.swf",
            panoXml: "assets/panos/wer-impft-mich/gsk_impf_mich.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://app.meet.ps/wer-impft-mich",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "wer-impft-mich"
            },
            blockLoginUntil: "2020-06-29 01:00:00 +0200",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "2999-12-01 18:00:00 +0200",
            i18nBlockedLoginTextKey: "login.werImpftMich.loginBlocked"
        },
        {
            key: "boc-2021",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2021-11-11 18:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/lmu/assets/js/krpano.swf",
            panoXml: "assets/panos/lmu/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: true,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            cometchat: {
                appID: "31423eff6d2e3b3",
                appRegion: "eu",
                authKey: "3ed00996bc8f1073259b37d2e7a3da8020f4cf5a",
                widgetID: "fad93c86-6ed7-4c3c-b8c1-a0402ef197da",
                docked: "true",
                alignment: "right",
                roundedCorners: "true",
                height: "4px",
                width: "4px"
            },
            meetps: "https://app.meet.ps/attendee/post-asco",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "boc-2021"
            },
            blockLoginUntil: "2020-11-11 16:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY", // <= text shown on login mask
            hideBreakoutButtonUntil: "2020-11-11 18:30:00 +0100"
        },
        {
            key: "immuntherapie-endometriumkarzinom",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            webinarStart: "2000-11-11 18:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/immuntherapie-endometriumkarzinom/assets/js/krpano.swf",
            panoXml: "assets/panos/immuntherapie-endometriumkarzinom/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://app.meet.ps/attendee/post-asco",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "immuntherapie-endometriumkarzinom"
            },
            blockLoginUntil: "2000-11-11 16:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY", // <= text shown on login mask
            hideBreakoutButtonUntil: "2000-11-11 18:30:00 +0100"
        },
        {
            key: "studien-informationstag",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2021-11-13 13:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/studien-informationstag/assets/js/krpano.swf",
            panoXml: "assets/panos/studien-informationstag/gsk_studieninformationstag.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/studien-informationstag/auditorium.png",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "",
            postLoginDisclaimer: {
                show: false,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/studien-informationstag",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "studien-informationstag"
            },
            blockLoginUntil: "2020-11-13 10:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY"
        },
        {
            key: "behandlung-ovar",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2021-11-24 18:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/behandlung-ovar/assets/js/krpano.swf",
            panoXml: "assets/panos/behandlung-ovar/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/behandlung-ovar",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "behandlung-ovar"
            },
            blockLoginUntil: "2020-11-24 12:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY"
        },
        {
            key: "xevudy",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            id3qsdn2dn: "7dae9b1c-4886-11ec-b839-0cc47a188158",
            id3qsdnSwitch1: "Deutsch",
            id3qsdnSwitch2: "Englisch",
            disable3qsdnChat: true,
            webinarStart: "2021-11-24 18:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/xevudy/assets/js/krpano.swf",
            panoXml: "assets/panos/xevudy/gsk_xevudy.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/xevudy/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/xevudy",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "xevudy"
            },
            blockLoginUntil: "2020-11-24 12:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY"
        },
        {
            key: "womens-health",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2022-03-05 11:00:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/womens-health/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-health/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/womens-health#ideas:-MwCobvOTgdOBrJ9PnI6",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "womens-health"
            },
            blockLoginUntil: "2020-11-24 12:00:00 +0100",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100"
        },
        {
            key: "onko-campus-hcp",
            id3qsdn: "afc693a8-a5b7-45a5-9aa1-33fadad09af6",
            disable3qsdnChat: true,
            webinarStart: "2022-05-10 16:30:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/womens-health/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-health/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-HCP",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "onko-campus-hcp"
            },
            blockLoginUntil: "9999-31-31 18:30:00 +0100",
            i18nBlockedLoginTextKey: "login.mfaHcp.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "onko-campus-mfa",
            id3qsdn: "adfe1f5d-8c3d-11ed-bf6a-3cecef385192",
            disable3qsdnChat: true,
            webinarStart: "2022-05-10 16:30:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/womens-health/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-health/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "61b1e2f580b2296cfdd0dc07",
            tawkSuffix: "1fmfdt4fu",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/Onco-MFA",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "onko-campus-mfa"
            },
            blockLoginUntil: "9999-31-31 18:30:00 +0100",
            i18nBlockedLoginTextKey: "login.mfaHcp.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "onko-campus-2023",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2023-03-15 18:30:00 +0100",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/womens-health/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-health/gsk-3.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/c2h3yny3",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "onko-campus-2023"
            },
            blockLoginUntil: "2020-05-10 16:00:00 +0200",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100",
            loginToLectureHall: true
        },
        {
            key: "womens-cancer",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2022-03-05 11:00:00 +0200",
            waitingRoomFeature: false,
            panoSwf: "assets/panos/womens-cancer/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-cancer/pano_c.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://meet.ps/womens-health#ideas:-MwCobvOTgdOBrJ9PnI6",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "womens-health"
            },
            blockLoginUntil: "2023-09-05 11:30:00 +0200",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100"
        },
        {
            key: "cd226",
            id3qsdn: "b9db26f2-7bfe-11ea-97a4-002590c750be",
            disable3qsdnChat: true,
            webinarStart: "2022-11-14 10:00:00 +0100",
            waitingRoomFeature: false,
            loginToLectureHall: true,
            panoSwf: "assets/panos/womens-cancer/assets/js/krpano.swf",
            panoXml: "assets/panos/womens-cancer/pano_c.xml",
            waitingRoomAssets: "/assets/img/lmu/waitingroom/",
            lecturehallBg: "/assets/img/lmu/lecturehall_bg.jpg",
            meetingBg: "/assets/img/lmu/meeting_bg.jpg",
            showStatusBox: false,
            tawkId: "5fa15019e019ee7748f05489",
            postLoginDisclaimer: {
                show: true,
                i18nTitleKey: "disclaimer.lmu.title",
                i18nContentKey: "disclaimer.lmu.content",
                i18nAgreeButtonKey: "disclaimer.lmu.button.agree"
            },
            meetps: "https://app.meet.ps/attendee/oj279733",
            losTrackingEndpoint: "https://infill-service-lostracking-gcr-qq72lvua4q-ey.a.run.app/",
            clickTracking: {
                endpoint: "https://infill-service-clicktracking-gcr-qq72lvua4q-ey.a.run.app",
                token: "jDt6sG2rqgsEjYFp5DBpe8eftKmyKauGSKcBN5ZjQQgH2LLnH5zK6uKJT3LgTgcT",
                tenantId: "cd226"
            },
            blockLoginUntil: "2022-11-14 10:00:00 +0100",
            i18nBlockedLoginTextKey: "login.loginBlocked",
            loginDateTextFormat: "DD.MM.YYYY",
            hideBreakoutButtonUntil: "9999-31-31 18:30:00 +0100"
        }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
