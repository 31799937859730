import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OAuthStorageAdapter } from "./OAuthStorageAdapter";
import { AuthenticationService } from "./services/authentication.service";

@Injectable()
export class AuthenticationGuard implements CanActivate {
    constructor(private router: Router, private oauthStorageAdapter: OAuthStorageAdapter, private oauthService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const organizationKey = this.oauthStorageAdapter.getCurrentOrganizationKey();
        if (!organizationKey) {
            this.router.navigate(["/login"]);
            return false;
        }

        const authDetails = this.oauthStorageAdapter.getCurrentOAuthResult();
        if (!authDetails) {
            this.router.navigate(["/login", organizationKey]);
            return false;
        }

        if (!authDetails.accessTokenExpiresAt) {
            this.router.navigate(["/login", organizationKey]);
            return false;
        }

        this.oauthService.profile().subscribe((result) => {});

        return true;
    }
}
