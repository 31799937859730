export enum eMeetingUserState {
    available = "available",
    occupied = "occupied"
}

export interface IMeetingUser {
    accessToken: string;
    roomId?: string;
    email: string;
    firstName?: string;
    lastName?: string;
    organization?: string;
    state?: eMeetingUserState;
    userId?: string;
}
