<div class="container">
    <header>
        <img src="assets/img/landingpages/behandlung-ovar/header.png" style="width: 35%;" />
    </header>
    <article>
        <br/>
        
        <h1><span class="orange" style="font-size: 2.00rem;">Die Behandlung des Ovarialkarzinoms – Standards und Improvisation: </span></h1>
        <h1><span style="font-size: 2.00rem;">24. November,  18:00 Uhr–20:00 Uhr</span></h1>

        <div>
            <button class="btn btn-primary btn-block loginButton" (click)="gotoLogin()" *ngIf="showLoginLink">
                Webinar Login
            </button>
        </div>

        <div class="app-registration greyBackground" *ngIf="showRegistrationFormView">
            <form name="form" #f="ngForm">
                <div class="cross-validation">
                    <div class="form-group">
                        <input id="firstName" name="firstname" #firstNameInput="ngModel" [(ngModel)]="this.registration.firstname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.first_name" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="firstNameInput?.errors?.required && (firstNameInput.dirty || firstNameInput.touched)">
                            <p>{{ "registration.validation.first_name" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="lastName" name="lastName" #lastNameInput="ngModel" [(ngModel)]="this.registration.lastname" type="text" class="form-control" required />
                        <label>{{ "registration.labels.last_name" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="lastNameInput.errors?.required && (lastNameInput.dirty || lastNameInput.touched)">
                            <p>{{ "registration.validation.last_name" | translate }}</p>
                        </div>
                    </div>

                    <div class="form-group">
                        <input id="institution" name="institution" #institutionInput="ngModel" [(ngModel)]="this.registration.misc.institution" type="text" class="form-control" required />
                        <label>{{ "registration.labels.institution" | translate }}*</label>
                        <i class="form-group__bar"></i>
                        <div class="validation-error alert alert-danger" *ngIf="institutionInput.errors?.required && (institutionInput.dirty || institutionInput.touched)">
                            <p>{{ "registration.validation.institution" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="email" name="email" #emailInput="ngModel" [(ngModel)]="this.registration.email" type="email" class="form-control" required email />
                        <label>{{ "registration.labels.email" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="emailInput.invalid && (emailInput.dirty || emailInput.touched)">
                            <p>{{ "registration.validation.email" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="password" name="password" #passwordInput="ngModel" [(ngModel)]="this.registration.password" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="passwordInput.errors?.required && (passwordInput.dirty || passwordInput.touched)">
                            <p>{{ "registration.validation.password" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <input id="passwordConfirm" #passwordConfirm="ngModel" name="passwordConfirm" [(ngModel)]="this.registration.passwordConfirm" type="password" class="form-control" required />
                        <label>{{ "registration.labels.password_confirm" | translate }}*</label>
                        <div class="validation-error alert alert-danger" *ngIf="!(this.registration.passwordConfirm === this.registration.password)">
                            <p>{{ "registration.validation.password_confirm_mismatch" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group form-group--float form-group--centered">
                        <input id="agbAccepted" #agbAcceptedInput="ngModel" name="agbAccepted" [(ngModel)]="this.registration.agbAccepted" type="checkbox" required />
                        <label id="agbAcceptLabel" for="agbAccepted">{{ "registration.labels.accept" | translate }}</label>
                        <div class="validation-error alert alert-danger" *ngIf="agbAcceptedInput.errors?.required && (agbAcceptedInput.dirty || agbAcceptedInput.touched)">
                            <p>{{ "registration.validation.terms_accepted" | translate }}</p>
                        </div>
                    </div>
        
                    <div class="form-group">
                        <label [innerHtml]="'registration.labels.termsnotes' | translate"></label>
                    </div>
        
                    <button class="btn btn-primary btn-block" type="submit" (click)="register()" [disabled]="f.invalid">
                        {{ "registration.buttons.register" | translate }}
                    </button>
                </div>
            </form>
        </div>

        <div class="app-registration-successful" *ngIf="showRegistrationSuccessfulView">
            <h4>{{ "registration.titles.registration_done" | translate }}</h4>
            <p>{{ "registration.paragraphs.registration_successful" | translate }}</p>
        </div>

        <section>
            <table class="mt-3 mb-3" style="width:100%;">
                <tr>
                    <td style="width: 145px;"><img src="assets/img/landingpages/behandlung-ovar/schmalfeldt.png" style="width: 100%; max-width:125px;" /></td>
                    <td>
                        Vorsitz:<br/>
                        <span class="strong">Prof. Dr. Barbara Schmalfeldt</span>
                    </td>
                </tr>
                <tr>
                    <td class="p-1" colspan="2">&nbsp;</td>
                </tr>
                <tr>
                    <td class="align-top">
                        18:00 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Begrüßung</span><br/>
                        Prof. Dr. Barbara Schmalfeldt/Dr. Bodo Räke
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        18:05 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Der Einsatz der Chemotherapie – Bewährte Standards und seltene Ausnahmen</span><br/>
                        PD Dr. Lars Hanker 
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        18:25 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Die Erhaltungstherapie – Zahlen, Daten, Fakten</span><br/>
                        Prof. Dr. Tjoung-Won Park-Simon
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        18:45 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Die Erhaltungstherapie – Therapieentscheidung: Helfen uns die Daten in der Praxis?</span><br/>
                        N. N.
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        19:05 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Fehlende Daten und seltene Phänotypen –  Entscheidungsmöglichkeiten bei komplizierter Datenlage</span><br/>
                        Prof. Dr. Barbara Schmalfeldt
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        19:25 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Podiumsdiskussion der Referenten</span><br/>
                    </td>
                </tr>
                <tr class="table-spacer">
                    <td colspan="10"></td>
                </tr>
                <tr>
                    <td class="align-top">
                        19:55 Uhr
                    </td>
                    <td>
                        <span class="orange strong">Zusammenfassung und Verabschiedung</span><br/>
                        Prof. Dr. Barbara Schmalfeldt/Dr. Bodo Räke
                    </td>
                </tr>
            </table>
        </section>
      
        <section class="icons">
            <div class="row">
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Agenda-Behandlung-Ovar.pdf" target="_blank" onclick="clicktracker.click('landingpage -> agenda');">
                        <p><mat-icon>assignment</mat-icon></p>
                        <p>Agenda</p>
                    </a>
                </div>
                <div class="col-sm-6 col-lg d-grid justify-content-center">
                    <a href="https://health-ecomm.com/GSK/Behandlung-Ovar.ics" onclick="clicktracker.click('landingpage -> calendar');">
                        <p><mat-icon>date_range</mat-icon></p>
                        <p>Kalendereintrag</p>
                    </a>
                </div>
            </div>
        </section>

        <section>
            <h2>Laptop-Nutzer:</h2>
            <p>Wir bitten Sie einen der folgenden Browser für den Zugang zum Event zu benutzen: Edge, Chrome oder Firefox</p>
        </section>

        <section>
            <h2>Servicekontakt:</h2>
            <p>Bei technischen Schwierigkeiten können Sie sich jederzeit an unseren Support wenden:</p>

            <p>Tel.: 02244 – 87 84 811</p>
            <p>E-Mail: <a href="mailto:support@infill.com">support@infill.com</a></p>
        </section>


        <p class="footnote">
            * Die erhobenen, personenbezogenen Daten dienen ausschließlich der Teilnehmerverwaltung  (Veranstalter und Partner) in Zusammenhang mit dieser Veranstaltung. Weitere Informationen finden Sie hier: 
            <a href="https://de.gsk.com/de-de/datenschutzerklaerung/" target="_blank">https://de.gsk.com/de-de/datenschutzerklaerung/</a>
            <br/><br/>
            <span style="font-size: 0.85rem;">NX-DE-NRP-WCNT-210001 10/2021</span>
        </p> 

    </article>
</div>
